import styles from './ProjectSidebar.module.scss'
import clsx from 'clsx'

export function ProjectSidebar({ className }) {
  return (
    <div className={clsx(className, styles.sidebar)}>
      <div className={styles.center}>
        <p className={styles.title}>Ventury market</p>
        <p className={styles.subtitle}>Скоро будет доступен</p>
      </div>
      <a
        className={styles.linkMore}
        href="https://myfuture.company/ventury-market"
        target="_blank"
      >
        Узнать больше
      </a>
    </div>
  )
}
