import React from 'react'
import styles from './Container.module.scss'

export const Container = ({
  children,
  className,
  isPadding = true,
  ...props
}) => {
  const drawerOpen = props.active ? styles.containerActive : ''
  const paddingStyle = isPadding ? styles.containerPadding : ''

  return (
    <div
      className={`${styles.container} ${drawerOpen} ${paddingStyle} ${className}`}
      style={props.styles}
    >
      {children}
    </div>
  )
}
