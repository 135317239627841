import styles from './InvestHeader.module.scss'
import { CloseIcon } from 'components/icons'

export function InvestHeader({ onCloseModal }) {
  return (
    <div className={styles.header}>
      <span className={styles.title}>Инвестировать в проект</span>
      <CloseIcon className={styles.icon} onClick={onCloseModal} />
    </div>
  )
}
