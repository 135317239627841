import styles from './ProjectHeader.module.scss'
import { Tag } from 'components/layout'
import arrow from '../../assets/images/right-arrow.png'
import { useHistory } from 'react-router'
export function ProjectHeader({ category, date, token }) {
  const history = useHistory()

  return (
    <div className={styles.upheader}>
      <img src={arrow} alt="" onClick={() => history.push('/')} />
      <div>
        <Tag color="#002143" styles={{ marginRight: '8px' }}>
          {token}
        </Tag>
        <Tag color="#F5F5F5">{category}</Tag>
      </div>
      <div>
        <dd>
          Размещение <span className={styles.mobileHidden}>на листинг</span>:{' '}
        </dd>
        <dt>{date}</dt>
      </div>
    </div>
  )
}
