import React from "react";
import styles from "./HeadingRow.module.scss"

export const HeadingRow = ({ heading, subheading, className = '' }) => {
  return (
    <div className={`${className} ${styles.headingRow}`}>
      {typeof heading === "object" ? (
        heading
      ) : (
        <h2 className={styles.heading}>{heading}</h2>
      )}
      {typeof subheading === "object" ? (
        subheading
      ) : (
        <small className={styles.subheading}>{subheading}</small>
      )}
    </div>
  )
}