import React from 'react'
import { Link } from 'react-router-dom'
import { Tag, InlineTags } from './Tag.jsx'
import styles from './Card.module.scss'
import { Button } from 'components/form'

export const Card = props => {
  return (
    <div className={styles.container}>
      <Link className={styles.wrapper} to={`/project/${props.token}`}>
        {props.img && (
          <div style={{ position: 'relative' }}>
            {props.tags.map(({ text, ...tagProps }) => (
              <Tag {...tagProps}>{text}</Tag>
            ))}
            <div className={styles.imageContainer}>
              <img className={styles.image} src={props.img} />
            </div>
            {props.title && (
              <div className={styles.titleBlock}>
                <h4 style={{ mixBlendMode: 'difference' }}>{props.title}</h4>
                <dd>Размещение:</dd>
                <dt>{props.date}</dt>
              </div>
            )}
          </div>
        )}
        <div className={styles.content}>
          {props.children}
          <div className={styles.contentBody}>
            {/* {props.contentTags && <InlineTags tags={props.contentTags} />} */}
            <progress max={100} value={props.progress}></progress>
            {/* <p className={styles.progressCount}>12 000 123 / 24 000 000</p> */}
            <Button type="primary">Узнать больше</Button>
          </div>
        </div>
      </Link>
    </div>
  )
}

export const Cards = props => {
  return <div className={styles.cardsContainer}>{props.children}</div>
}
