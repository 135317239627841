import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { NavLink } from 'components/navigation/NavLink'
import { Button, TextInput } from 'components/form'
import styles from './KYCPage.module.scss'
import { Container, SupportHeader } from 'components/layout'
import {
  createUserDocument,
  usersCollection,
  storageRef,
} from 'services/firebase'
import { useAuth } from 'hooks/useAuth'
import ImageUploader from 'react-images-upload'
import { getISODate } from 'utils'

const imageUploaderText = `Загрузите 3 (три) фото.

1. Основной разворот на котором отчетливо видно
ваши паспортные данные и фото
2. Разворот с пропиской, текст должен читаться.
3. Селфи с паспортом в руках.`

export const KYCPage = props => {
  const [success, setSuccess] = useState(false)
  const [images, setImages] = useState([])

  const { user, getUserData, sendMail } = useAuth()

  const handleSubmit = async ev => {
    ev.preventDefault()
    ev.stopPropagation()

    if (images.length > 5 || images.length < 1)
      return alert('Убедитесь что добавили все фото паспорта!')

    const form = Object.values(ev.target).reduce((acc, item) => {
      if (item.name) return { ...acc, [item.name]: item.value }
      return { ...acc }
    }, {})

    const imagePromises = []

    images.forEach((image, idx) => {
      imagePromises.push(
        storageRef
          .ref('images')
          .child(`${user.uid}_passport_image_${idx + 1}`)
          .put(image)
          .then(snapshot => snapshot.ref.getDownloadURL())
      )
    })
    const imagesURLs = await Promise.all(imagePromises)

    const kycData = {
      ...form,
      kyc_state: 'started',
      timestamp: getISODate(),
      passport_images: imagesURLs,
    }

    usersCollection
      .doc(user.uid)
      .set(kycData, { merge: true })
      .then(doc => {
        setSuccess(true)
        getUserData(user)
        sendMail('kyc_started')
      })
  }

  const handleImageSelect = (pictureFiles, pictureDataUrl) => {
    console.log(pictureFiles, pictureDataUrl)
    setImages(pictureFiles)
  }

  return (
    <Container>
      <SupportHeader />
      <div className={styles.container}>
        <h1 style={{ marginTop: '40px', marginBottom: '12px' }}>
          ВЕРИФИКАЦИЯ KYC
        </h1>
        <p style={{ fontSize: '16px', fontWeight: '500', marginTop: '12px' }}>
          Для продолжения заполните все поля
        </p>
        <form
          style={{ position: 'relative' }}
          onSubmit={handleSubmit}
        >
          <TextInput
            required
            label={'Номер телефона'}
            name={'phone'}
            placeholder="+7 (999) 999 99 99"
          />
          <TextInput
            required
            label={'Фамилия'}
            name={'last_name'}
            placeholder="Введите фамилию"
          />
          <TextInput
            required
            label={'Имя'}
            name={'first_name'}
            placeholder="Введите имя"
          />
          <TextInput
            required
            label={'Отчество'}
            name={'third_name'}
            placeholder="Введите отчество"
          />
          <TextInput
            required
            label={'Место рождения'}
            name={'birthplace'}
            placeholder="Введите адрес"
          />
          <TextInput
            required
            label={'Дата рождения'}
            name={'dob'}
            type="date"
          />
          <TextInput
            required
            label={'№ и серия паспорта'}
            name={'passport_number'}
            placeholder="ХХХХ-ХХХХХ"
          />
          <div className={styles.col2}>
            <TextInput
              required
              label={'Код подразделения'}
              name={'passport_code'}
              placeholder="ХХХ-ХХХ"
            />
            <TextInput
              required
              label={'Дата выдачи'}
              name={'passport_date'}
              type="date"
            />
          </div>
          <ImageUploader
            withPreview
            withLabel
            onChange={handleImageSelect}
            label={`Загрузите 3 (три) фото.

            1. Основной разворот на котором отчетливо видно
            ваши паспортные данные и фото
            2. Разворот с пропиской, текст должен читаться.
            3. Селфи с паспортом в руках.`}
            buttonText="Выбрать фото"
            imgExtension={['.jpg', '.jpeg']}
            maxFileSize={5242880}
          />
          <Button style={{ marginBottom: '30px' }}>Отправить данные</Button>
          <CSSTransition in={success} timeout={333} unmountOnExit mountOnEnter>
            {state => (
              <div className={`${styles.popup} ${styles[state]}`}>
                <h4>Данные отправлены</h4>
                <p>
                  Спасибо! Ваши данные успешно отправлены. Обычно проверка
                  занимает не более 24 часов.
                </p>
                <p className={styles.blue}>
                  Результаты верификации вы получите на Email указнный при
                  регистрации.
                </p>
                <NavLink
                  type="button"
                  to="/"
                  onClick={() => document.location.reload()}
                >
                  Продолжить
                </NavLink>
              </div>
            )}
          </CSSTransition>
        </form>
      </div>
    </Container>
  )
}
