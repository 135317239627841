import React, { useState } from 'react'
import { HeadingRow } from '../components/layout/HeadingRow'
import { Card, Cards } from '../components/layout/Card'
import { InfoLink } from 'components/elements'
import { PageLayout, Sidebar } from 'components/layout'
import projects from 'services/projects.json'
import monarchImg from 'assets/images/Monarch.png'
import CKPImg from 'assets/images/CKP.png'
import networkgramImg from 'assets/images/Networkgram.png'
import newformatImg from 'assets/images/New_format.png'
import forwardArrow from 'assets/images/forward_arrow.svg'
import { useAuth } from 'hooks/useAuth'
import styles from './IndexPage.module.scss'
import { Link } from 'react-router-dom'
export const trimString = (string: string, deleteFrom: number) =>
  string.length >= deleteFrom ? string.slice(0, deleteFrom - 1) + '...' : string

const IndexPage = props => {
  const { user } = useAuth()
  console.log(projects)
  // const projects = JSON.parse(projectsJSON, swag => {
  //   console.log(swag)
  // })

  const [currentFilter, setCurrentFilter] = useState(null)

  return (
    <PageLayout>
      {user?.kyc_state === 'started' && (
        <div className={styles.blackBox}>
          <div>
            <h2>KYC отправлен</h2>
            <p>Ожидайте подтверждения</p>
          </div>
          <Link className={styles.boxButton} to="/kyc-pending">
            <img src={forwardArrow} alt="" />
          </Link>
        </div>
      )}
      {/* <HeadingRow
        heading={"Предстоящий листинг"}
        subheading={"Проекты станут доступны в течении 30 дней"}
      />
      <Slider>
        <SliderImage
          src={slider1}
          href="https://myfuture.company/dev/sharing"
        />
        <SliderImage src={slider2} href="https://corebl.io/" />
        <SliderImage
          src={slider3}
          href="https://myfuture.company/dev/livecoin"
        />
        <SliderImage src={slider4} href="https://myfuture.company/dev/platon" />
        <SliderImage src={slider5} href="https://myfuture.company/dev/avatar" />
        <SliderImage
          src={slider6}
          href="https://myfuture.company/dev/sbmining"
        />
      </Slider> */}
      <div className={styles.header}>
        <HeadingRow
          className={styles.headerLeft}
          heading={'Проекты'}
          subheading={'Отобранные для листинга на платформе'}
        />
        <div className={styles.headerRight}>
          {/* <InfoLink className={styles.infoLink}>
            Критерии оценки проектов
          </InfoLink> */}
          <InfoLink className={styles.infoLink}>
            <span style={{ color: '#2dd172' }}>Узнайте</span> как производится
            оценка проектов
          </InfoLink>
        </div>
      </div>
      {currentFilter && <h1>{currentFilter}</h1>}
      <div className={styles.wrapper}>
        <Cards>
          {Object.entries(projects)
            ?.filter(([_, project]) =>
              currentFilter ? project.category === currentFilter : true
            )
            .map(([_, project], index) => {
              return (
                <Card
                  contentTags={[
                    { text: 'Экспертная оценка' },
                    { text: 'Действующее производство' },
                  ]}
                  tags={[
                    { position: 'topleft', color: '#002143', text: _ },
                    {
                      position: 'topright',
                      color: '#fff',
                      text: project.category,
                    },
                  ]}
                  token={_}
                  key={_}
                  title={project.title}
                  date={project.date}
                  progress={project.progress}
                  img={`https://cdn.myfuture.company/${project.img}.jpg`}
                >
                  <p>
                    {trimString(project.bigText.replaceAll('\\n', '\n'), 138)}
                  </p>
                </Card>
              )
            })}
        </Cards>
        <Sidebar className={styles.sidebar} onFilterChange={setCurrentFilter} />
      </div>
    </PageLayout>
  )
}

export default IndexPage
