import styles from './ProjectPrice.module.scss'

export function ProjectPrice({ handleBuyTokenPopup, price, buttonDisabled }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.left}>
          <span className={styles.price}>
            Стоимость 1 ЦФА = <span className={styles.priceBold}>{price}$</span>
          </span>
          {/* <span className={styles.priceBuy}>
            Доступно к покупке: 10 250 ЦФА
          </span> */}
        </div>
        <button
          // disabled={buttonDisabled}
          className={styles.investButton}
          onClick={handleBuyTokenPopup}
        >
          Инвестировать
        </button>
      </div>
    </div>
  )
}
