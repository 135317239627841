import styles from './CitySelect.module.scss'
import clsx from 'clsx'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useEffect, useRef, useState } from 'react'
import { getCitiesApi } from 'utils/api'

export function CitySelect({
  className,
  opened,
  search,
  onChooseCity,
  onCloseSelect,
}) {
  const [suggestions, setSuggestions] = useState([])

  const selectRef = useRef(null)

  useOnClickOutside(selectRef, () => {
    onCloseSelect()
  })

  const handleMakeRequest = async search => {
    const newSuggestions = await getCitiesApi(search)

    setSuggestions(newSuggestions)
  }

  useEffect(() => {
    handleMakeRequest(search)
  }, [search])

  const renderCities = () => {
    if (suggestions.length) {
      return (
        <div
          ref={selectRef}
          className={clsx(
            className,
            styles.wrapper,
            search && search.length && opened && styles.opened
          )}
        >
          {suggestions.map((city, index) => {
            return (
              <p
                key={index}
                className={styles.city}
                onClick={() => onChooseCity(city.value)}
              >
                {city.data.city}
              </p>
            )
          })}
        </div>
      )
    } else {
      return null
    }
  }

  return renderCities()
}
