const url =
  'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
const token = 'ece9d872a9a8a5838fcdb087e2114d9638007c85'

export const getCitiesApi = async search => {
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Token ' + token,
    },
    body: JSON.stringify({
      query: search,
      from_bound: { value: 'city' },
      to_bound: { value: 'city' },
    }),
  }

  try {
    const response = await fetch(url, options)
    const data = await response.text()

    return JSON.parse(data).suggestions
  } catch (e) {
    console.log(e)
    return []
  }
}
