import styles from './ProjectHeading.module.scss'

export function ProjectHeading({ title }) {
  return (
    <div className={styles.heading}>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.subtitle}>Название компании</p>
    </div>
  )
}
