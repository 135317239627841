import React from 'react'
import styles from './Button.module.scss'

export const Button = ({ children, ...props }) => {
  const types = {
    blue: styles.buttonBlue,
    red: styles.buttonRed,
    primary: styles.buttonPrimary,
  }
  return (
    <button
      className={types[props.type] || styles.button}
      style={props.style}
      {...props}
    >
      {children}
    </button>
  )
}
