import Web3 from 'web3'
import Constants from './../constants'
import { addTransaction, errorsCollection } from '../firebase'
import { toast } from 'react-toastify'
import { ethereum as _eth } from "./eth"

let web3js = ''

let accounts = ''

let ethereum = ''

export const checkIsWeb3 = setIsEthereum => {
  ethereum = window.ethereum

  if (!ethereum || !ethereum.isMetaMask) {
    setIsEthereum(false)
  } else {
    setIsEthereum(true)
  }
}

export const connectWallet = async (
  saveAddress,
  saveReferrers,
  saveReferrals,
  setIsEthereum
) => {

  await _eth.connectAccount()
  web3js = new Web3(window.web3.currentProvider)
  console.log('===========', web3js)
  accounts = await web3js.eth.getAccounts()
  // saveAddress(accounts[0])
  // getReferrerAddress(accounts[0], saveReferrers)
  // getReferrals(accounts[0], saveReferrals)
  // setIsEthereum(true)

  return accounts[0]
}

export const buyToken = async (
  userAddress,
  amount,
  token,
  referrer = '0x84A869c4D502C12cEE49B79ab2FcC794EF5729E0',
  goHome
) => {
  // console.log(address, amount, token, referrer)
  if (!referrer) throw new Error('Для покупки нужно указать адрес реферера')

  if (!userAddress)
    return toast.error(
      'MetaMask не подключен, обновите страницу и проверьте MetaMask'
    )
  let tetherContract = new web3js.eth.Contract(
    Constants.TETHER_TOKEN_ABI,
    Constants.TETHER_TOKEN
  )

  try {
    const allowed = await tetherContract.methods
      .allowed(userAddress, Constants[`${token}_TOKEN`])
      .call()

    console.log(allowed, amount)

    if (allowed > 0 && parseInt(allowed) !== amount * 1_000_000) {
      await tetherContract.methods
        .approve(Constants[`${token}_TOKEN`], 0 * 1_000_000)
        .send({ from: userAddress })
    }
    let exchange = await tetherContract.methods
      .approve(Constants[`${token}_TOKEN`], amount * 1_000_000)
      .send({ from: userAddress })
      .on('transactionHash', hash => {
        console.log('Approval txhash ==>>', hash)
        toast('Транзакция подтверждения отправлена!')
        if (typeof goHome === 'function') goHome()
      })
      .on('receipt', () => {
        toast.success('Подтверждение успешно!')
        try {
          startBuyToken(userAddress, amount, referrer, token)
        } catch (error) {
          console.log('Start buy error', error)
          toast.error('Что то пошло не так при попытке получить разрешение.')
        }
      })
      .on('error', err => {
        switch (err.code) {
          case 4001:
            toast.error('Пользователь отклонил транзакцию')
            break

          default:
            toast.error(err.message)
            break
        }
      })

    console.log(exchange)
  } catch (err) {
    console.log('error ==>>', err)
  }
}

export const startBuyToken = async (address, amount, refId, token) => {
  const contract = new web3js.eth.Contract(
    Constants[`${token}_TOKEN_ABI`],
    Constants[`${token}_TOKEN`]
  )

  await contract.methods
    .buyToken(amount * 1_000_000, refId)
    .send({ from: address })
    .on('transactionHash', hash => {
      console.log('Buy txhash ==>>', hash)
      toast('Транзакция покупки токена отправлена!')
    })
    .on('receipt', async receipt => {
      console.log('Buy token receipt: ', receipt)
      toast.success('Покупка успешна')
      addTransaction(token, amount, receipt.transactionHash)
    })
    .on('error', err => {
      console.log('Buy token error: ', err)
      errorsCollection.doc(Date.now().toString()).set({ ...err })
      toast.error('Произошла ошибка при попытке купить токен', {
        autoClose: 10000000,
      })
    })
}

export const getReferalInfo = async address => {
  const contracts = {
    TMONContract: new web3js.eth.Contract(
      Constants.TMON_TOKEN_ABI,
      Constants.TMON_TOKEN
    ),
    CKPContract: new web3js.eth.Contract(
      Constants.TCKP_TOKEN_ABI,
      Constants.TCKP_TOKEN
    ),
    TNWGContract: new web3js.eth.Contract(
      Constants.TNWG_TOKEN_ABI,
      Constants.TNWG_TOKEN
    ),
    TNFMContract: new web3js.eth.Contract(
      Constants.TNFM_TOKEN_ABI,
      Constants.TNFM_TOKEN
    ),
  }

  const tmon = await contracts.TMONContract.methods
    .getReferrerInfo(address)
    .call()
  const ckp = await contracts.CKPContract.methods
    .getReferrerInfo(address)
    .call()
  const tnwg = await contracts.TNWGContract.methods
    .getReferrerInfo(address)
    .call()
  const tnfm = await contracts.TNFMContract.methods
    .getReferrerInfo(address)
    .call()
  const referrers = {
    tmon: tmon.referrer,
    ckp: ckp.referrer,
    tnwg: tnwg.referrer,
    tnfm: tnfm.referrer,
  }
  const referralsPromises = Object.values(contracts).map(contract =>
    contract.methods.getReferralInfo(address, 0, 100_000_000).call()
  )
  const referrals = await Promise.all(referralsPromises)
  console.log(referrals)
  referrals.reduce((acc, item) => [...acc, ...item.referrals], [])

  return {
    referrers,
    referrals: {
      referrals: referrals.reduce(
        (acc, item) => [...acc, ...item.referrals],
        []
      ),
      bonuses: referrals.reduce((acc, item) => [...acc, ...item.bonuses], []),
    },
  }
}
