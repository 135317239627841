import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Constants from 'services/constants';
import { saveReferralAddress } from 'store/actions';

const UserReferal = (props) => {
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		props.saveReferralAddress(location.pathname.substr(9));
		history.push('/');
	}, []);

	return <Redirect to='/login' />;
	// <p>Redirecting...</p>
};

const mapDispatchToProps = (dispatch) => ({
	saveReferralAddress: (address) =>
		saveReferralAddress(
			dispatch({
				type: Constants.SET_REFERRAL_ADDRESS,
				data: { referralAddress: address },
			})
		),
});

export default connect(null, mapDispatchToProps)(UserReferal);
