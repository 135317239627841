/*eslint no-unused-expressions: "off"*/
// Lighten or Darken RGB/hex color
export function getContrastYIQ(hexcolor) {
  hexcolor = hexcolor.replace('#', '')
  var r = parseInt(hexcolor.substr(0, 2), 16)
  var g = parseInt(hexcolor.substr(2, 2), 16)
  var b = parseInt(hexcolor.substr(4, 2), 16)
  var yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'black' : 'white'
}

export const formatDate = date => {
  const formatter = new Intl.DateTimeFormat('ru')
  try {
    return formatter.format(new Date(date))
  } catch (e) {
    return new Date(date)
  }
}

export const getISODate = () => new Date().toISOString()

export function errorsLocale(error) {
  switch (error.message) {
    case 'execution reverted.':
      return 'Совершите транзакцию с нулевой (0) суммой'
    case 'MetaMask Tx Signature: User denied transaction signature.':
      return 'Транзакция отменена'
    default:
      return error
  }
}

export function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text)
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    var textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand('copy') // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }
}
