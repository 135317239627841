import React from 'react'

export function ExclameIcon({ className }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zM9 9v6h2V9H9zm0-4v2h2V5H9z"
        fill="#2DD172"
        fillRule="nonzero"
      />
    </svg>
  )
}
