export function EnterIcon({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <path
          d="M6.667 4v1.333H3.333v7.334h7.334V9.333H12v4a.667.667 0 0 1-.667.667H2.667A.667.667 0 0 1 2 13.333V4.667C2 4.298 2.298 4 2.667 4h4zM14 2v5.333h-1.333V4.275L7.47 9.471 6.53 8.53l5.194-5.196H8.667V2H14z"
          fill="#516CFB"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}
