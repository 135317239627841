import { Button, TextInput } from 'components/form'
import { HeadingRow, PageLayout } from 'components/layout'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import copyIcon from 'assets/images/copy_icon.svg'
import styles from './ReferalPage.module.scss'
import { getUsersCollection, usersCollection } from 'services/firebase'

const ReferalPage = props => {
  const [referals, setReferal] = useState([])
  const [emails, setEmails] = useState({})
  const [path, setPath] = useState('')
  const address = useSelector(state => state.address)

  const getReferalEmails = async () => {
    if (!address) return
    await getUsersCollection('referalNumber', '==', address).then(documents => {
      documents.forEach(doc => {
        const { email, wallet_address } = doc.data()
        if (wallet_address)
          setEmails(state => ({ ...state, [wallet_address]: email }))
      })
    })
  }

  const inputRef = React.createRef()

  const copyLink = ({ target }) => {
    inputRef.current.select()
    document.execCommand('copy')
  }

  useEffect(() => {
    setPath(window.location.origin)
  }, [])

  useEffect(() => {
    getReferrals()
    getReferalEmails()
  }, [props.referrals])

  const getReferrals = () => {
    const tempRefs = []
    if (!!props.referrals?.referrals?.length) {
      for (let [index, item] of props.referrals.referrals.entries()) {
        tempRefs.push({
          ref: props.referrals?.referrals[index],
          bonus: props.referrals?.bonuses[index],
        })
      }

      setReferal(tempRefs)
    }
  }
  const referalsLen = Object.keys(emails).length
  return (
    <PageLayout>
      <HeadingRow heading="Реферальная система" />
      {address && (
        <div className={styles.copy}>
          <TextInput
            label="Ваша реферальная ссылка:"
            value={`${path}/register?ref=${props.address}`}
            style={{
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            readOnly
            onClick={copyLink}
            ref={inputRef}
          />
          <img
            src={copyIcon}
            alt="copyIcon"
            style={{ pointerEvents: 'none' }}
          />
        </div>
      )}
      {address ? (
        <p className={styles.p}>
          Скопируйте и отправьте реферальную ссылку. От всех пользователей,
          которые пройдут регистрацию по вашей ссылке и купят ЦФА, вам будет
          начислен бонус <b>до 20%</b> от их покупки.
        </p>
      ) : (
        <p className={styles.p}>
          Для генерации рефереальной ссылки откройте данную страницу <br /> c
          использованием Metamask |{' '}
          <a
            href="https://myfuture.company/metamask"
            target="_blank"
            rel="noreferrer"
          >
            <b>Инструкция</b>
          </a>
        </p>
      )}
      <HeadingRow
        heading="Ваши рефералы"
        subheading={!referalsLen ? 'Рефералов нет' : referalsLen}
      />

      <ul className={styles.referalList}>
        {referals.map(referal => (
          <div className={styles.referalCard}>
            <h4>
              <b>Реферал</b>
            </h4>
            <span>[{referal.ref}]</span>
            <p>
              <b>Email:</b> emails[referal.ref]
            </p>
            <div>
              <b>Ваш бонус:</b> {referal.bonus / 1_000_000} USD
            </div>
          </div>
        ))}
      </ul>
    </PageLayout>
  )
}

const mapStateToProps = state => {
  return {
    address: state.address,
    referrals: state.referrals,
  }
}

export default connect(mapStateToProps, null)(ReferalPage)
