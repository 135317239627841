import styles from './ProjectMain.module.scss'
import clsx from 'clsx'
import {
  Tag,
  ProjectDocuments,
  ProjectPrice,
  ProjectHeader,
  ProjectHeading,
  ProjectDescription,
} from 'components/layout'
import { NavLink } from 'components/navigation/NavLink'
const shortNames = {
  TAVT: 'avatar',
  TLFC: 'lc',
  TPSH: 'ps',
  TCRB: 'cbl',
  TNFM: 'nf',
  TCKP: 'ckp',
  TMON: 'monarch',
  TNWG: 'ntwg',
  TALE: 'tale'
}

export function ProjectMain({
  className,
  data,
  token,
  handleBuyTokenPopup,
  onOpenModal,
}) {
  return (
    <div className={clsx(className, styles.main)}>
      <ProjectHeader token={token} category={data.category} date={data.date} />
      <ProjectHeading title={data.title} />
      <div className={styles.info}>
        <div className={styles.infoFirst}>
          <div className={styles.infoBlock}>
            <dd className={styles.expertName}>Оценка экспертов</dd>
            <dt className={styles.ratingText}>
              {data.rating} <span className={styles.grayText}>/</span>{' '}
              <span className={styles.greenText}>10</span>
            </dt>
          </div>
          <div className={styles.infoGroup}>
            <div className={styles.infoBlock}>
              <dd>Доход</dd>
              <dt>
                {data.roi}%
              </dt>
            </div>
            <div className={styles.infoBlock}>
              <dd>Раунд инвестиций</dd>
              <dt>{'Pre-seed' ||data.dynamics}</dt>
            </div>
          </div>
        </div>
        <div className={styles.infoSecond}>
          <div className={styles.infoBlock}>
            <dd>Вид ЦФА</dd>
            <dt className={styles.largeText}>{data.dfaType}</dt>
          </div>
          <div className={styles.infoBlock}>
            <dd>Инвесторов</dd>
            <dt className={styles.largeText}>-</dt>
          </div>
          {/* <div className={styles.infoBlock}>
          <dd>Доступно ЦФА</dd>
          <dt>{data.availableTokens}</dt>
        </div> */}
          <div className={styles.infoBlock}>
            <dd>Рынок сбыта</dd>
            <dt className={styles.largeText}>{data.markets}</dt>
          </div>
        </div>
      </div>
      <div className={styles.progressBlock}>
        <progress className={styles.progress} max={100} value={data.progress}></progress>
        <div className={styles.progressValue}>
          <span className={styles.progressName}>Прогресс продажи ЦФА</span>
          <span className={styles.progressStatus}>
            -<span className={styles.progressSlash}>/</span>{' '}
            <span className={styles.progressMaxValue}>-</span>
          </span>
        </div>
      </div>
      <ProjectDescription img={`https://cdn.myfuture.company/${data.img}.jpg`} bigText={data.bigText} />
      <ProjectDocuments shortName={shortNames[token]} />
      <ProjectPrice price={data.price} buttonDisabled={data.buyDisabled} handleBuyTokenPopup={onOpenModal} />
      <NavLink
        href={`https://myfuture.company/call/${shortNames[token]}`}
        style={{
          position: 'absolute',
          left: '35%',
          bottom: '0px',
          color: '#000',
          transform: 'translateX(-35%)',
          textDecoration: 'underline',
        }}
      >
        Связаться с проектом
      </NavLink>
    </div>
  )
}
