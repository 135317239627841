import Constants from './../../services/constants';

export const saveAddress = (dispatch) => async (address) => {
    dispatch({ type: Constants.SET_ADDRESS, data: { address } });
};

export const saveReferralAddress = (dispatch) => async (address) => {
    dispatch({ type: Constants.SET_REFERRAL_ADDRESS, data: { address } });
};

export const saveReferrers = (dispatch) => async (address) => {
    dispatch({ type: Constants.SET_REFERRERS, data: { address } });
};

export const saveReferrals = (dispatch) => async (address) => {
    dispatch({ type: Constants.SET_REFERRALS, data: { address } });
};

export const setIsWeb3 = (dispatch) => async (isIt) => {
    dispatch({ type: Constants.SET_REFERRALS, data: { isIt } });
};