import styles from './InvestSteps.module.scss'
import clsx from 'clsx'

export function InvestSteps({ className, current = 0, length = 3 }) {
  const renderSteps = () => {
    return Array(length)
      .fill(0)
      .map((i, index) => {
        return (
          <span
            key={index}
            className={clsx(styles.step, current >= index && styles.stepActive)}
          ></span>
        )
      })
  }

  return <div className={clsx(className, styles.steps)}>{renderSteps()}</div>
}
