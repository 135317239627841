import styles from './UserPanel.module.scss'
import { NavLink } from './NavLink'
import telegram from 'assets/images/telegram.png'
import { ExitIcon } from 'components/icons'

export function UserPanel({ user, address, logout, kyc_state }) {
  return (
    <>
      {user?.admin && (
        <NavLink className={styles.link} to="/admin/users">
          Админка
        </NavLink>
      )}
      <div className={styles.footer}>
        <a className={styles.telegram} href="https://myfuture.company/tg">
          <img className={styles.telegramImage} src={telegram} alt="" />
          <span className={styles.telegramName}>
            Узнавайте новости{' '}
            <span className={styles.telegramBold}>первыми</span>
          </span>
        </a>
        <div className={styles.account}>
          <span className={styles.accountTitle}>Аккаунт</span>
          <span className={styles.accountEmail}>{user?.email}</span>
          <span className={styles.accountKyc}>
            KYC:{' '}
            <span
              className={`${styles.accountStatus} ${
                user.kyc_state === 'passed' ? styles.accountActive : ''
              }`}
            >
              {user.kyc_state === 'passed' ? 'ПРОЙДЕНО' : 'НЕ ПРОЙДЕНО'}
            </span>
          </span>
        </div>
        {!address ? (
          <div className={styles.metamask}>
            <span className={styles.metamaskStatus}>MetaMask не подключен</span>
            <span className={styles.metamaskIcon}></span>
          </div>
        ) : (
          <div className={`${styles.metamask} ${styles.metamaskActive}`}>
            <span className={styles.metamaskStatus}>MetaMask connection</span>
            <span className={styles.metamaskIconActive}></span>
          </div>
        )}
        <div className={styles.bottom}>
          <button onClick={logout} className={styles.sign_out}>
            <ExitIcon />
          </button>
        </div>
      </div>
    </>
  )
}
