import { EnterIcon } from 'components/icons'
import styles from './ProjectDocuments.module.scss'
import clsx from 'clsx'

export function ProjectDocuments({ shortName }) {
  return (
    <div className={styles.documents}>
      <p className={styles.name}>Документы</p>
      <div className={styles.wrapper}>
        <a
          className={styles.item}
          target="_blank"
          rel="noreferrer"
          href={`https://myfuture.company/doc/${shortName}/wp`}
        >
          <div className={styles.block}>
            <span className={styles.title}>Whitepaper</span>
          </div>
          <EnterIcon className={styles.icon} />
        </a>
        <a
          className={styles.item}
          target="_blank"
          rel="noreferrer"
          href={`https://myfuture.company/doc/${shortName}/pitch`}
        >
          <div className={styles.block}>
            <span className={styles.title}>Презентация</span>
          </div>
          <EnterIcon className={styles.icon} />
        </a>
        <a
          className={styles.item}
          target="_blank"
          rel="noreferrer"
          href={`https://myfuture.company/doc/${shortName}/economy`}
        >
          <div className={styles.block}>
            <span className={styles.title}>Эконом заключение</span>
            <span className={styles.subtitle}>Стандарт МСФО</span>
          </div>
          <EnterIcon className={styles.icon} />
        </a>
        <a
          className={styles.item}
          target="_blank"
          rel="noreferrer"
          href={`https://myfuture.company/doc/${shortName}/em`}
        >
          <div className={styles.block}>
            <span className={styles.title}>Решение об&nbsp;эмиссии</span>
          </div>
          <EnterIcon className={styles.icon} />
        </a>
        <a
          className={styles.item}
          target="_blank"
          rel="noreferrer"
          href={`https://myfuture.company/doc/${shortName}/investfiat`}
        >
          <div className={clsx(styles.block, styles.blockRow)}>
            <span className={styles.title}>Договор инвестирования</span>
            <span className={styles.subheading}>FIAT</span>
          </div>
          <EnterIcon className={styles.icon} />
        </a>
        <a
          className={styles.item}
          target="_blank"
          rel="noreferrer"
          href={`https://myfuture.company/doc/${shortName}/investusdt`}
        >
          <div className={clsx(styles.block, styles.blockRow)}>
            <span className={styles.title}>Договор инвестирования</span>
            <span className={styles.subheading}>USDT</span>
          </div>
          <EnterIcon className={styles.icon} />
        </a>
      </div>
    </div>
  )
}
