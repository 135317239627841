import styles from './InvestModal.module.scss'
import Modal from 'react-modal'
import {
  InvestHeader,
  InvestChoiceStep,
  InvestAcceptStep,
  InvestFiat,
  InvestUsdt,
} from '.'
import { useEffect, useState } from 'react'

export function InvestModal({
  isModal,
  onCloseModal,
  fiatDisabled,
  buyDisabled,
  referrer,
  token,
  address,
  price,
}) {
  const [choice, setChoice] = useState('USDT')
  const [stepLength, setStepLength] = useState(3)
  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
    if (choice === 'FIAT') {
      setStepLength(3)
    } else {
      setStepLength(2)
    }
  }, [choice])

  const handleCloseModal = () => {
    onCloseModal()

    setTimeout(() => {
      setCurrentStep(0)
    }, 500)
  }

  const renderContent = () => {
    if (choice === 'FIAT') {
      switch (currentStep) {
        case 0:
          return (
            <InvestChoiceStep
              count={stepLength}
              choice={choice}
              onChangeStep={setCurrentStep}
              onChangeChoice={setChoice}
              fiatDisabled={fiatDisabled}
              buyDisabled={buyDisabled}
              token={token}
            />
          )
        case 1:
          return <InvestAcceptStep onChangeStep={setCurrentStep} />
        case 2:
          return <InvestFiat onChangeStep={setCurrentStep} />
        default:
          return <p>Что-то пошло не так.</p>
      }
    } else {
      switch (currentStep) {
        case 0:
          return (
            <InvestChoiceStep
              count={stepLength}
              choice={choice}
              onChangeStep={setCurrentStep}
              onChangeChoice={setChoice}
              fiatDisabled={fiatDisabled}
              buyDisabled={buyDisabled}
              token={token}
            />
          )
        case 1:
          return (
            <InvestUsdt
              referrer={referrer}
              address={address}
              token={token}
              closeModal={onCloseModal}
              count={stepLength}
              isDisabled={true}
              price={price}
            />
          )
        default:
          return <p>Что-то произошло не так.</p>
      }
    }
  }

  return (
    <Modal
      isOpen={isModal}
      onRequestClose={handleCloseModal}
      contentLabel="Invest"
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
      closeTimeoutMS={500}
    >
      <InvestHeader onCloseModal={handleCloseModal} />
      {renderContent()}
    </Modal>
  )
}
