const _eth = window.ethereum

export const ethereum = {
  exists: !!_eth,
  currentAccount: null,
  async onAccountChanged(handleChangeAccounts: (accounts: string[]) => void) {
    _eth.on('accountsChanged', handleChangeAccounts)
  },
  async checkAccounts(): Promise<string> {
    const [selectedAddress] = await _eth.request({
      method: 'eth_accounts',
    })
    this.currentAccount = selectedAddress

    return selectedAddress
  },
  async connectAccount(): Promise<string> {
    const [selectedAddress] = await _eth.request({
      method: 'eth_requestAccounts',
    })
    this.currentAccount = selectedAddress

    return selectedAddress
  },
  async switchNetwork(chainId: string) {
    return _eth.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainId }],
    })
  },
  async addNetwork(
    chainId: string,
    options?: { chainName: string, rpcUrls: string[] }
  ) {
    try {
      await this.switchNetwork(chainId)
    } catch (errorOnSwitch) {
      console.log('Failed to switch to network with chain id #%d', chainId)

      try {
        await _eth.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: chainId,
              chainName: options?.chainName,
              rpcUrls: options?.rpcUrls,
            },
          ],
        })
      } catch (errorOnAdd) {
        throw new Error(
          "Couldn't switch and add network with chain id #%d" + chainId
        )
      }
    }
  },
}
