import { ProtectedRoute } from 'components/navigation/ProtectedRoute'
import { useAuth } from 'hooks/useAuth'
import {
  AdminPanel,
  IndexPage,
  KYCPage,
  Login,
  NotFound,
  ProjectPage,
  ReferalPage,
  Register,
  RestorePassword,
} from 'pages'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useLocation } from 'react-router'
import './App.scss'
import { Loading, PendingKYC } from './components/layout/Loading'
import {
  checkIsWeb3,
  connectWallet as web3ConnectWallet,
  getReferalInfo,
  getReferrals,
} from 'services/Web3/Web3'
import { connect } from 'react-redux'
import {
  saveAddress,
  saveReferrals,
  saveReferrers,
  saveReferralAddress,
  setIsWeb3,
} from 'store/actions'

import Constants from 'services/constants'
import { AdminService } from 'utils/adminService'

const LoggedIn = ({
  user,
  setIsWeb3,
  isWeb3,
  saveAddress,
  saveReferrals,
  saveReferrers,
  updateUserData,
  ...props
}) => {
  useEffect(() => {
    checkIsWeb3(setIsWeb3)
  }, [])

  useEffect(() => {
    if (isWeb3 && user?.kyc_state === 'passed') connectWallet()
  }, [isWeb3])

  const connectWallet = async () => {
    try {
      const address = await web3ConnectWallet()
      saveAddress(address)
      const { referrers, referrals } = await getReferalInfo(address)
      saveReferrers(referrers)
      saveReferrals(referrals)
      if (!user.wallet_address || user.wallet_address !== address)
        updateUserData({ wallet_address: address })
      setIsWeb3(true)
    } catch (error) {
      setIsWeb3(false)
      console.log(error)
    }
  }

  return (
    <>
      <Switch>
        <Redirect from="/login" to="/" />
        <Route path="/project/:id">
          <ProjectPage />
        </Route>
        <ProtectedRoute
          component={ReferalPage}
          exact
          path="/referal"
          condition={user.kyc_state === 'passed'}
          redirectTo="/know-your-customer"
        />
        <ProtectedRoute
          component={KYCPage}
          exact
          path="/know-your-customer"
          condition={user.kyc_state === 'not_started'}
          redirectTo="/kyc-pending"
        />
        <ProtectedRoute
          component={PendingKYC}
          exact
          path="/kyc-pending"
          condition={user.kyc_state === 'started'}
          redirectTo="/"
        />
        <Route exact path="/">
          <IndexPage />
        </Route>
        <ProtectedRoute
          component={AdminPanel}
          path="/admin"
          condition={!!user.admin}
        />
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </>
  )
}

function App(props) {
  const location = useLocation()
  const refAddress = location.search.split('ref=')[1]

  useEffect(() => {
    if (refAddress && refAddress.length == 42) {
      props.saveReferralAddress(refAddress)
    }
  }, [])

  const { user, updateUserData } = useAuth()

  return (
    <div className="App" style={{ height: '100vh', overflowX: 'hidden' }}>
      {/* <button onClick={() => AdminService()}>Send mail</button> */}
      {user === null ? (
        <Loading />
      ) : !user ? (
        <>
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/register">
              <Register referalId={props.referralAddress} />
            </Route>
            <Route exact path="/restore-password">
              <RestorePassword />
            </Route>
            <Redirect from="/" to="/login" />
          </Switch>
        </>
      ) : (
        <>
          <LoggedIn {...props} user={user} updateUserData={updateUserData} />
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    address: state.address,
    referrals: state.referrals,
    referralAddress: state.referralAddress,
    isWeb3: state.isWeb3,
  }
}

const mapDispatchToProps = dispatch => ({
  setIsWeb3: isIt =>
    setIsWeb3(
      dispatch({ type: Constants.SET_IS_WEB3, data: { isWeb3: isIt } })
    ),
  saveAddress: address =>
    saveAddress(
      dispatch({ type: Constants.SET_ADDRESS, data: { address: address } })
    ),
  saveReferrers: address =>
    saveReferrers(
      dispatch({ type: Constants.SET_REFERRERS, data: { referrers: address } })
    ),
  saveReferrals: address =>
    saveReferrals(
      dispatch({ type: Constants.SET_REFERRALS, data: { referrals: address } })
    ),
  saveReferralAddress: address =>
    saveReferralAddress(
      dispatch({
        type: Constants.SET_REFERRAL_ADDRESS,
        data: { referralAddress: address },
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
