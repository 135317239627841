import React from 'react'

const positionsMap = {
  topleft: { position: 'absolute', top: '10px', left: '10px' },
  topright: { position: 'absolute', top: '10px', right: '10px' },
  bottomleft: { position: 'absolute', bottom: '10px', left: '10px' },
  bottomright: { position: 'absolute', bottom: '10px', right: '10px' },
}

const colorsMap = {
  '#30323d': { color: '#fff', backgroundColor: '#30323d' },
  '#002143': { color: '#fff', backgroundColor: '#002143' },
  '#fff': { color: '#002143', backgroundColor: '#fff' },
  '#F5F5F5': { color: '#002143', backgroundColor: '#F5F5F5' },
}

export const Tag = ({ position, color, styles, ...props }) => {
  const style = {
    display: 'inline-block',
    fontSize: '10px',
    padding: '4px 8px',
    borderRadius: '2px',
    fontWeight: '450',
    zIndex: '5',
    ...colorsMap[color],
    ...positionsMap[position],
    ...styles,
  }

  return <span style={style}>{props.children}</span>
}

export const InlineTags = ({ tags }) => (
  <div
    style={{
      display: 'flex',
      flexFlow: 'row wrap',
      width: '100%',
      justifyContent: 'space-between',
    }}
  >
    {tags?.map(tag => (
      <Tag
        styles={{
          fontSize: '11px',
          lineHeight: '10px',
          padding: '8px 12px',
          borderRadius: '6px',
          fontWeight: 'normal',
          marginBottom: '2px',
          backgroundColor: '#30323d',
          color: '#fff',
        }}
        key={tag.text}
        {...tag}
      >
        {tag.text}
      </Tag>
    ))}
  </div>
)
