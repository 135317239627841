import { useAuth } from "hooks/useAuth"
import React, { useState } from "react"
import { Button, TextInput } from "components/form"
import { Container, HeadingRow, SupportHeader } from "components/layout"
import styles from "./Login.module.scss"
import { NavLink } from "components/navigation/NavLink"

// const errorsTranslations = {
//   "auth/email-already-in-use": "Пользователь с таким e-mail уже существует.",
//   "auth/invalid-email": "Проверьте правильность написания почты. (пример: example@gmail.com)",
//   "auth/weak-password": "Слабый пароль, придумайте сложнее",
// }

export const RestorePassword = props => {
  const { resetPassword } = useAuth()
  const [errors, setErrors] = useState({})
  const [success, setSuccess] = useState(false)
  const handleSuccess = () => { setSuccess(true) }

  const handleErrors = error => {
    setErrors(state => ({ ...state, ...error }))
  }

  const handleSubmit = ev => {
    ev.preventDefault()
    ev.stopPropagation()
    const { email } = ev.currentTarget
    resetPassword(email.value, handleSuccess, handleErrors)
  }

  return (
    <Container>
      <div className={styles.container}>
        <SupportHeader logo backTo={"/login"} />
        { !success ? <><HeadingRow
          className="no-gap"
          heading={<h2 className={styles.heading}>Восстановление пароля</h2>}
          subheading={
            <p className={styles.subheading}>
              Введите e-mail, на почту придет письмо с инструкцией
            </p>
          }
        />
         <form action="a" onSubmit={handleSubmit}>
          <TextInput
            required
            name={"email"}
            placeholder="Введите e-mail"
            error={errors.email}
          />
          {errors.email && (
            <div style={{ marginTop: "10px", color: "#c70000" }}>
              {errors.email}
            </div>
          )}
          <Button type="blue">Восстановить пароль</Button>
        </form></> : <HeadingRow
          className="no-gap"
          heading={<h2 className={styles.heading}>Письмо отправлено вам на почту!</h2>}
          subheading={
            <p className={styles.subheading}>
              Следуйте инструкциям в письме, письмо действительно <br/> в течении 10 минут
            </p>
          }
        />}
        <NavLink
          className={styles.resetPass}
          style={{ marginTop: "auto" }}
          href="https://myfuture.company/support"
          target="_blank">
          ТЕХНИЧЕСКАЯ ПОДДЕРЖКА
        </NavLink>
        <small className={styles.version}>Версия 1.0.0</small>
      </div>
    </Container>
  )
}
