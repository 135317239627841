import styles from './ProjectDescription.module.scss'
import { PlayIcon } from 'components/icons'

export function ProjectDescription({ img, bigText }) {
  return (
    <>
      <figure className={styles.figure}>
        <img className={styles.image} src={img} alt="" />
        {/* <PlayIcon className={styles.playIcon} /> */}
        {/* <span className={styles.address}>Обращение CEO</span> */}
      </figure>
      <div className={styles.content}>
        <p className={styles.top}>
          {/* <span className={styles.ceo}>CEO</span>
          <span className={styles.name}>Киров Александр</span> */}
        </p>
        <p
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: bigText?.replaceAll('\\n', '<br/>'),
          }}
        ></p>
      </div>
    </>
  )
}
