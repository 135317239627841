import { NavLink } from 'components/navigation/NavLink'
import React from 'react'
import { Link } from 'react-router-dom'
import arrow from '../../assets/images/arrow.svg'
import logo from '../../assets/images/logo-small.svg'
import styles from './SupportHeader.module.scss'

export const SupportHeader = props => {
  return (
    <div className={styles.container} style={props.style}>
      <Link to={props.backTo || '/'}>
        <img src={arrow} />
      </Link>
      {props.support && (
        <NavLink href="https://myfuture.company/support" target="_blank">
          <h3>ТЕХНИЧЕСКАЯ ПОДДЕРЖКА</h3>
        </NavLink>
      )}
      {props.logo && <img src={logo} />}
    </div>
  )
}
