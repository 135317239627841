const Constants = {
  SET_ADDRESS: 'SET_ADDRESS',
  SET_REFERRAL_ADDRESS: 'SET_REFERRAL_ADDRESS',
  SET_REFERRERS: 'SET_REFERRERS',
  SET_REFERRALS: 'SET_REFERRALS',
  SET_IS_WEB3: 'SET_IS_WEB3',

  TETHER_TOKEN: '0xdac17f958d2ee523a2206206994597c13d831ec7', // usdt

  TMON_TOKEN: '0x640d2bc7FC845bACDB6fD1e601AE556C73D3C212', //TMON
  TCKP_TOKEN: '0x981fb3098aAB5C766f2812b4aE8E33ccAa9Daf1F', //CKP
  TNWG_TOKEN: '0x1E3B7c9DF6b65F36ab8505e2622ad1F5132EB09D', //TNWG
  TNFM_TOKEN: '0x1D421E2AEE94727FA0416BBd2f9b493af3eFF3aa', //TNFM
  TALE_TOKEN: '0x42297c7E24962Ce6B4A9745Db679bb52E44B224f', //TALE

  TETHER_TOKEN_ABI: [
    {
      constant: true,
      inputs: [],
      name: 'name',
      outputs: [{ name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: '_upgradedAddress', type: 'address' }],
      name: 'deprecate',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_spender', type: 'address' },
        { name: '_value', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'deprecated',
      outputs: [{ name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: '_evilUser', type: 'address' }],
      name: 'addBlackList',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'totalSupply',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_from', type: 'address' },
        { name: '_to', type: 'address' },
        { name: '_value', type: 'uint256' },
      ],
      name: 'transferFrom',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'upgradedAddress',
      outputs: [{ name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '', type: 'address' }],
      name: 'balances',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'decimals',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'maximumFee',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: '_totalSupply',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'unpause',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_maker', type: 'address' }],
      name: 'getBlackListStatus',
      outputs: [{ name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { name: '', type: 'address' },
        { name: '', type: 'address' },
      ],
      name: 'allowed',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'paused',
      outputs: [{ name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: 'who', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'pause',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getOwner',
      outputs: [{ name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'owner',
      outputs: [{ name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'symbol',
      outputs: [{ name: '', type: 'string' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_to', type: 'address' },
        { name: '_value', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: 'newBasisPoints', type: 'uint256' },
        { name: 'newMaxFee', type: 'uint256' },
      ],
      name: 'setParams',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: 'amount', type: 'uint256' }],
      name: 'issue',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: 'amount', type: 'uint256' }],
      name: 'redeem',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { name: '_owner', type: 'address' },
        { name: '_spender', type: 'address' },
      ],
      name: 'allowance',
      outputs: [{ name: 'remaining', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'basisPointsRate',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '', type: 'address' }],
      name: 'isBlackListed',
      outputs: [{ name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: '_clearedUser', type: 'address' }],
      name: 'removeBlackList',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'MAX_UINT',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: '_blackListedUser', type: 'address' }],
      name: 'destroyBlackFunds',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { name: '_initialSupply', type: 'uint256' },
        { name: '_name', type: 'string' },
        { name: '_symbol', type: 'string' },
        { name: '_decimals', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: 'amount', type: 'uint256' }],
      name: 'Issue',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: 'amount', type: 'uint256' }],
      name: 'Redeem',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: 'newAddress', type: 'address' }],
      name: 'Deprecate',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, name: 'feeBasisPoints', type: 'uint256' },
        { indexed: false, name: 'maxFee', type: 'uint256' },
      ],
      name: 'Params',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, name: '_blackListedUser', type: 'address' },
        { indexed: false, name: '_balance', type: 'uint256' },
      ],
      name: 'DestroyedBlackFunds',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: '_user', type: 'address' }],
      name: 'AddedBlackList',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: '_user', type: 'address' }],
      name: 'RemovedBlackList',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: 'owner', type: 'address' },
        { indexed: true, name: 'spender', type: 'address' },
        { indexed: false, name: 'value', type: 'uint256' },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: 'from', type: 'address' },
        { indexed: true, name: 'to', type: 'address' },
        { indexed: false, name: 'value', type: 'uint256' },
      ],
      name: 'Transfer',
      type: 'event',
    },
    { anonymous: false, inputs: [], name: 'Pause', type: 'event' },
    { anonymous: false, inputs: [], name: 'Unpause', type: 'event' },
  ],

  TALE_TOKEN_ABI: [
    {
      inputs: [
        { internalType: 'address', name: 'USDTAddr', type: 'address' },
        { internalType: 'address', name: 'TOKENAddr', type: 'address' },
        { internalType: 'address', name: 'initialOwner', type: 'address' },
        { internalType: 'address', name: 'initialFeeWallet', type: 'address' },
        { internalType: 'address', name: 'initialFundWallet', type: 'address' },
        { internalType: 'address', name: 'defaultReferrer', type: 'address' },
        { internalType: 'uint256', name: '_rateMul', type: 'uint256' },
        { internalType: 'uint256', name: '_rateDiv', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'oldFeeWallet',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'newFeeWallet',
          type: 'address',
        },
      ],
      name: 'FeeWalletChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'oldFundWallet',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'newFundWallet',
          type: 'address',
        },
      ],
      name: 'FundWalletChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'usdt',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokens',
          type: 'uint256',
        },
      ],
      name: 'Purchased',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'oldRateMul',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'oldRateDiv',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'newRateMul',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'newRateDiv',
          type: 'uint256',
        },
      ],
      name: 'RateChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'referral',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'refPercent',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
      ],
      name: 'RefBonus',
      type: 'event',
    },
    {
      constant: true,
      inputs: [],
      name: 'FEE_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'FUND_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'PERCENT_DIV',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'REF_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'TOKEN',
      outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'USDT',
      outputs: [{ internalType: 'contract IUSDT', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: 'usdtAmount', type: 'uint256' },
        { internalType: 'address', name: 'referrer', type: 'address' },
      ],
      name: 'buyToken',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'defaultRef',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'feeWallet',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'fundWallet',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getAvailableTokens',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'usdt', type: 'uint256' }],
      name: 'getEstimation',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'getPurchasedOf',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'from', type: 'uint256' },
        { internalType: 'uint256', name: 'to', type: 'uint256' },
      ],
      name: 'getReferralInfo',
      outputs: [
        { internalType: 'address[]', name: 'referrals', type: 'address[]' },
        { internalType: 'uint256[]', name: 'bonuses', type: 'uint256[]' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'getReferrerInfo',
      outputs: [
        { internalType: 'address', name: 'referrer', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
        { internalType: 'uint256', name: 'amountOfReferrals', type: 'uint256' },
        { internalType: 'uint256', name: 'totalBonuses', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getSoldTokens',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'paused',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: 'newRateMul', type: 'uint256' },
        { internalType: 'uint256', name: 'newRateDiv', type: 'uint256' },
      ],
      name: 'setRate',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'ref', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
      ],
      name: 'setReferrer',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: '_fee', type: 'address' },
        { internalType: 'address', name: '_fund', type: 'address' },
        { internalType: 'address', name: '_defaultRef', type: 'address' },
      ],
      name: 'setWallets',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'switchState',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'tokenBalanceOf',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'usdtBalanceOf',
      outputs: [{ internalType: 'uint256', name: 'usdt', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'users',
      outputs: [
        { internalType: 'uint256', name: 'purchased', type: 'uint256' },
        { internalType: 'address', name: 'referrer', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
        { internalType: 'uint256', name: 'totalBonuses', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'ERC20Token', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],

  TMON_TOKEN_ABI: [
    {
      inputs: [
        { internalType: 'address', name: 'USDTAddr', type: 'address' },
        { internalType: 'address', name: 'TOKENAddr', type: 'address' },
        { internalType: 'address', name: 'initialOwner', type: 'address' },
        { internalType: 'address', name: 'initialFeeWallet', type: 'address' },
        { internalType: 'address', name: 'initialFundWallet', type: 'address' },
        { internalType: 'address', name: 'defaultReferrer', type: 'address' },
        { internalType: 'uint256', name: '_rateMul', type: 'uint256' },
        { internalType: 'uint256', name: '_rateDiv', type: 'uint256' },
        { internalType: 'bool', name: '_rateChangeable', type: 'bool' },
        { internalType: 'bool', name: '_withdrawable', type: 'bool' },
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'oldFeeWallet',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'newFeeWallet',
          type: 'address',
        },
      ],
      name: 'FeeWalletChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'oldFundWallet',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'newFundWallet',
          type: 'address',
        },
      ],
      name: 'FundWalletChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'usdt',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokens',
          type: 'uint256',
        },
      ],
      name: 'Purchased',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'oldRateMul',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'oldRateDiv',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'newRateMul',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'newRateDiv',
          type: 'uint256',
        },
      ],
      name: 'RateChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'referral',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'refPercent',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
      ],
      name: 'RefBonus',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'SetterAdded',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'SetterRemoved',
      type: 'event',
    },
    {
      constant: true,
      inputs: [],
      name: 'FEE_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'FUND_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'PERCENT_DIV',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'REF_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'TOKEN',
      outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'USDT',
      outputs: [{ internalType: 'contract IUSDT', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'addSetter',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: 'usdtAmount', type: 'uint256' },
        { internalType: 'address', name: 'referrer', type: 'address' },
      ],
      name: 'buyToken',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'defaultRef',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'feeWallet',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'fundWallet',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getAvailableTokens',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'usdt', type: 'uint256' }],
      name: 'getEstimation',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'getPurchasedOf',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'from', type: 'uint256' },
        { internalType: 'uint256', name: 'to', type: 'uint256' },
      ],
      name: 'getReferralInfo',
      outputs: [
        { internalType: 'address[]', name: 'referrals', type: 'address[]' },
        { internalType: 'uint256[]', name: 'bonuses', type: 'uint256[]' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'getReferrerInfo',
      outputs: [
        { internalType: 'address', name: 'referrer', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
        { internalType: 'uint256', name: 'amountOfReferrals', type: 'uint256' },
        { internalType: 'uint256', name: 'totalBonuses', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getSoldTokens',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'isSetter',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'rateChangeable',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeSetter',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'bool', name: '_withdrawable', type: 'bool' },
        { internalType: 'bool', name: '_rateChangeable', type: 'bool' },
      ],
      name: 'setParameters',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: 'newRateMul', type: 'uint256' },
        { internalType: 'uint256', name: 'newRateDiv', type: 'uint256' },
      ],
      name: 'setRate',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'ref', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
      ],
      name: 'setReferrer',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'tokenBalanceOf',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'usdtBalanceOf',
      outputs: [{ internalType: 'uint256', name: 'usdt', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'users',
      outputs: [
        { internalType: 'uint256', name: 'purchased', type: 'uint256' },
        { internalType: 'address', name: 'referrer', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
        { internalType: 'uint256', name: 'totalBonuses', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'ERC20Token', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'withdrawable',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  ],

  TCKP_TOKEN_ABI: [
    {
      inputs: [
        { internalType: 'address', name: 'USDTAddr', type: 'address' },
        { internalType: 'address', name: 'TOKENAddr', type: 'address' },
        { internalType: 'address', name: 'initialOwner', type: 'address' },
        { internalType: 'address', name: 'initialFeeWallet', type: 'address' },
        { internalType: 'address', name: 'initialFundWallet', type: 'address' },
        { internalType: 'address', name: 'defaultReferrer', type: 'address' },
        { internalType: 'uint256', name: '_rateMul', type: 'uint256' },
        { internalType: 'uint256', name: '_rateDiv', type: 'uint256' },
        { internalType: 'bool', name: '_rateChangeable', type: 'bool' },
        { internalType: 'bool', name: '_withdrawable', type: 'bool' },
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'oldFeeWallet',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'newFeeWallet',
          type: 'address',
        },
      ],
      name: 'FeeWalletChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'oldFundWallet',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'newFundWallet',
          type: 'address',
        },
      ],
      name: 'FundWalletChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'usdt',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokens',
          type: 'uint256',
        },
      ],
      name: 'Purchased',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'oldRateMul',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'oldRateDiv',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'newRateMul',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'newRateDiv',
          type: 'uint256',
        },
      ],
      name: 'RateChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'referral',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'refPercent',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
      ],
      name: 'RefBonus',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'SetterAdded',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'SetterRemoved',
      type: 'event',
    },
    {
      constant: true,
      inputs: [],
      name: 'FEE_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'FUND_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'PERCENT_DIV',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'REF_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'TOKEN',
      outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'USDT',
      outputs: [{ internalType: 'contract IUSDT', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'addSetter',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: 'usdtAmount', type: 'uint256' },
        { internalType: 'address', name: 'referrer', type: 'address' },
      ],
      name: 'buyToken',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'defaultRef',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'feeWallet',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'fundWallet',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getAvailableTokens',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'usdt', type: 'uint256' }],
      name: 'getEstimation',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'getPurchasedOf',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'from', type: 'uint256' },
        { internalType: 'uint256', name: 'to', type: 'uint256' },
      ],
      name: 'getReferralInfo',
      outputs: [
        { internalType: 'address[]', name: 'referrals', type: 'address[]' },
        { internalType: 'uint256[]', name: 'bonuses', type: 'uint256[]' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'getReferrerInfo',
      outputs: [
        { internalType: 'address', name: 'referrer', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
        { internalType: 'uint256', name: 'amountOfReferrals', type: 'uint256' },
        { internalType: 'uint256', name: 'totalBonuses', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getSoldTokens',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'isSetter',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'rateChangeable',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeSetter',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'bool', name: '_withdrawable', type: 'bool' },
        { internalType: 'bool', name: '_rateChangeable', type: 'bool' },
      ],
      name: 'setParameters',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: 'newRateMul', type: 'uint256' },
        { internalType: 'uint256', name: 'newRateDiv', type: 'uint256' },
      ],
      name: 'setRate',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'ref', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
      ],
      name: 'setReferrer',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'tokenBalanceOf',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'usdtBalanceOf',
      outputs: [{ internalType: 'uint256', name: 'usdt', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'users',
      outputs: [
        { internalType: 'uint256', name: 'purchased', type: 'uint256' },
        { internalType: 'address', name: 'referrer', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
        { internalType: 'uint256', name: 'totalBonuses', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'ERC20Token', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'withdrawable',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  ],

  TNWG_TOKEN_ABI: [
    {
      inputs: [
        { internalType: 'address', name: 'USDTAddr', type: 'address' },
        { internalType: 'address', name: 'TOKENAddr', type: 'address' },
        { internalType: 'address', name: 'initialOwner', type: 'address' },
        { internalType: 'address', name: 'initialFeeWallet', type: 'address' },
        { internalType: 'address', name: 'initialFundWallet', type: 'address' },
        { internalType: 'address', name: 'defaultReferrer', type: 'address' },
        { internalType: 'uint256', name: '_rateMul', type: 'uint256' },
        { internalType: 'uint256', name: '_rateDiv', type: 'uint256' },
        { internalType: 'bool', name: '_rateChangeable', type: 'bool' },
        { internalType: 'bool', name: '_withdrawable', type: 'bool' },
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'oldFeeWallet',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'newFeeWallet',
          type: 'address',
        },
      ],
      name: 'FeeWalletChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'oldFundWallet',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'newFundWallet',
          type: 'address',
        },
      ],
      name: 'FundWalletChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'usdt',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokens',
          type: 'uint256',
        },
      ],
      name: 'Purchased',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'oldRateMul',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'oldRateDiv',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'newRateMul',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'newRateDiv',
          type: 'uint256',
        },
      ],
      name: 'RateChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'referral',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'refPercent',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
      ],
      name: 'RefBonus',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'SetterAdded',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'SetterRemoved',
      type: 'event',
    },
    {
      constant: true,
      inputs: [],
      name: 'FEE_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'FUND_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'PERCENT_DIV',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'REF_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'TOKEN',
      outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'USDT',
      outputs: [{ internalType: 'contract IUSDT', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'addSetter',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: 'usdtAmount', type: 'uint256' },
        { internalType: 'address', name: 'referrer', type: 'address' },
      ],
      name: 'buyToken',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'defaultRef',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'feeWallet',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'fundWallet',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getAvailableTokens',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'usdt', type: 'uint256' }],
      name: 'getEstimation',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'getPurchasedOf',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'from', type: 'uint256' },
        { internalType: 'uint256', name: 'to', type: 'uint256' },
      ],
      name: 'getReferralInfo',
      outputs: [
        { internalType: 'address[]', name: 'referrals', type: 'address[]' },
        { internalType: 'uint256[]', name: 'bonuses', type: 'uint256[]' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'getReferrerInfo',
      outputs: [
        { internalType: 'address', name: 'referrer', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
        { internalType: 'uint256', name: 'amountOfReferrals', type: 'uint256' },
        { internalType: 'uint256', name: 'totalBonuses', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getSoldTokens',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'isSetter',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'rateChangeable',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeSetter',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'bool', name: '_withdrawable', type: 'bool' },
        { internalType: 'bool', name: '_rateChangeable', type: 'bool' },
      ],
      name: 'setParameters',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: 'newRateMul', type: 'uint256' },
        { internalType: 'uint256', name: 'newRateDiv', type: 'uint256' },
      ],
      name: 'setRate',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'ref', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
      ],
      name: 'setReferrer',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'tokenBalanceOf',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'usdtBalanceOf',
      outputs: [{ internalType: 'uint256', name: 'usdt', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'users',
      outputs: [
        { internalType: 'uint256', name: 'purchased', type: 'uint256' },
        { internalType: 'address', name: 'referrer', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
        { internalType: 'uint256', name: 'totalBonuses', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'ERC20Token', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'withdrawable',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  ],

  TNFM_TOKEN_ABI: [
    {
      inputs: [
        { internalType: 'address', name: 'USDTAddr', type: 'address' },
        { internalType: 'address', name: 'TOKENAddr', type: 'address' },
        { internalType: 'address', name: 'initialOwner', type: 'address' },
        { internalType: 'address', name: 'initialFeeWallet', type: 'address' },
        { internalType: 'address', name: 'initialFundWallet', type: 'address' },
        { internalType: 'address', name: 'defaultReferrer', type: 'address' },
        { internalType: 'uint256', name: '_rateMul', type: 'uint256' },
        { internalType: 'uint256', name: '_rateDiv', type: 'uint256' },
        { internalType: 'bool', name: '_rateChangeable', type: 'bool' },
        { internalType: 'bool', name: '_withdrawable', type: 'bool' },
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'oldFeeWallet',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'newFeeWallet',
          type: 'address',
        },
      ],
      name: 'FeeWalletChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'oldFundWallet',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'newFundWallet',
          type: 'address',
        },
      ],
      name: 'FundWalletChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'usdt',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokens',
          type: 'uint256',
        },
      ],
      name: 'Purchased',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'oldRateMul',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'oldRateDiv',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'newRateMul',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'newRateDiv',
          type: 'uint256',
        },
      ],
      name: 'RateChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'referral',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'refPercent',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
      ],
      name: 'RefBonus',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'SetterAdded',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'SetterRemoved',
      type: 'event',
    },
    {
      constant: true,
      inputs: [],
      name: 'FEE_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'FUND_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'PERCENT_DIV',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'REF_PERCENT',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'TOKEN',
      outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'USDT',
      outputs: [{ internalType: 'contract IUSDT', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'addSetter',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: 'usdtAmount', type: 'uint256' },
        { internalType: 'address', name: 'referrer', type: 'address' },
      ],
      name: 'buyToken',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'defaultRef',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'feeWallet',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'fundWallet',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getAvailableTokens',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'usdt', type: 'uint256' }],
      name: 'getEstimation',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'getPurchasedOf',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'from', type: 'uint256' },
        { internalType: 'uint256', name: 'to', type: 'uint256' },
      ],
      name: 'getReferralInfo',
      outputs: [
        { internalType: 'address[]', name: 'referrals', type: 'address[]' },
        { internalType: 'uint256[]', name: 'bonuses', type: 'uint256[]' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'getReferrerInfo',
      outputs: [
        { internalType: 'address', name: 'referrer', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
        { internalType: 'uint256', name: 'amountOfReferrals', type: 'uint256' },
        { internalType: 'uint256', name: 'totalBonuses', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getSoldTokens',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'isSetter',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'rateChangeable',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeSetter',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'bool', name: '_withdrawable', type: 'bool' },
        { internalType: 'bool', name: '_rateChangeable', type: 'bool' },
      ],
      name: 'setParameters',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: 'newRateMul', type: 'uint256' },
        { internalType: 'uint256', name: 'newRateDiv', type: 'uint256' },
      ],
      name: 'setRate',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'ref', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
      ],
      name: 'setReferrer',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'tokenBalanceOf',
      outputs: [{ internalType: 'uint256', name: 'tokens', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'usdtBalanceOf',
      outputs: [{ internalType: 'uint256', name: 'usdt', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'users',
      outputs: [
        { internalType: 'uint256', name: 'purchased', type: 'uint256' },
        { internalType: 'address', name: 'referrer', type: 'address' },
        { internalType: 'uint256', name: 'refPercent', type: 'uint256' },
        { internalType: 'uint256', name: 'totalBonuses', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'ERC20Token', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'withdrawable',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  ],
}

export default Constants
