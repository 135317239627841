import styles from './InvestChoiceStep.module.scss'
import clsx from 'clsx'
import { InvestSteps } from '.'
import { useState } from 'react'
import { buyToken } from 'services/Web3/Web3'
import { Button } from 'components/form'
import { useAuth } from 'hooks/useAuth'

export function InvestUsdt({
  count,
  isDisabled = false,
  token,
  address,
  closeModal,
  price,
}) {
  const { user } = useAuth()
  const [amount, setAmount] = useState(1)

  const handleChangeValue = e => {
    if (!e.target.value.match('[0-9]*')) return
    setAmount(Number(e.target.value))
  }

  const summ = price * amount

  const handleMakeTransaction = async () => {
    console.log(user?.referalNumber)
    await buyToken(
      address,
      Math.floor(summ),
      token,
      user?.referalNumber || '0x84A869c4D502C12cEE49B79ab2FcC794EF5729E0'
    )
    closeModal()
  }

  return (
    <div className={clsx(styles.block, styles.blockPadding)}>
      <div className={styles.top}>
        <span className={styles.step}>шаг 2</span>
        <span className={styles.name}>Инвестиции в USDT</span>
        <a className={styles.link} href="https://myfuture.company/instruction">
          Инструкция инвестирования с помощью USDT
        </a>
      </div>
      <div className={styles.footer}>
        <p className={styles.footerPrice}>Стоимость 1 ЦФА = {price}$</p>
        <p className={styles.footerDescription}>
          Введите количество количество ЦФА
        </p>
        <div className={styles.footerButtons}>
          <div className={styles.footerWrapper}>
            <button
              className={clsx(styles.footerButton, styles.footerRemoveButton)}
              onClick={() => amount > 1 && setAmount(value => value - 1)}
            >
              -
            </button>
            <input
              type="text"
              name="number"
              value={amount}
              onChange={handleChangeValue}
              className={styles.footerInput}
            />
            <button
              className={styles.footerButton}
              onClick={() => setAmount(value => value + 1)}
            >
              +
            </button>
          </div>
          <p className={styles.summ}>
            Сумма <span>{summ.toFixed(2)}</span>
          </p>
          <Button
            onClick={handleMakeTransaction}
            className={clsx(styles.button)}
          >
            Совершить транзакцию
          </Button>
        </div>
        <InvestSteps className={styles.steps} length={count} current={1} />
      </div>
      <a className={styles.support} href="https://www.myfuture.company/support">
        Техническая поддержка
      </a>
    </div>
  )
}
