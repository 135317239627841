export function ExitIcon() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 20a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h14zM5 14l-5-4 5-4v3h6v2H5v3z"
        fill="#30323D"
        fillRule="nonzero"
        opacity=".5"
      />
    </svg>
  )
}
