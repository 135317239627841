import { Route } from 'react-router'
import { AdminUsers } from './AdminUsers'
import { Analytics } from './Analytics'

export const AdminPanel = props => {
  return (
    <>
      <br />
      <Route exact path="/admin/users" component={AdminUsers} />
      <Route path="/admin/analytics" component={Analytics} />
    </>
  )
}
