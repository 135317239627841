import { getUsersCollection, storageRef, usersCollection } from 'services/firebase'

const imagesRef = storageRef.ref('images')

export async function AdminService() {
  const firebaseUsers = await getUsersCollection()
  const promises = []

  // firebaseUsers.forEach(userDocument =>
  //   {
  //     if (userDocument.referalNumber)
  //   }
  // )

  // const result = await Promise.all(promises)
  // // searchStorage()
  // Object.entries(successFiles).forEach(([id, imagesArray]) => {
  //   usersCollection.doc(id).set({ passport_images: imagesArray }, { merge: true })
  // })
}

