import React from 'react'
import styles from './NavLink.module.scss'
import { NavLink as RouterNavLink } from 'react-router-dom'

export const NavLink = ({ type = 'link', color = '', className, ...props }) => {
  let myClassName = ''
  myClassName += className + ' '
  myClassName += styles[type] + ' '
  myClassName += styles[color] + ' '

  const activeStyle = props.myActiveStyle
    ? { color: '#000', boxShadow: '0 12px 34px 0 rgba(0, 0, 0, 0.05)' }
    : { backgroundColor: '#e7e7e7', borderRadius: 8 }

  return props.to ? (
    <RouterNavLink
      activeStyle={activeStyle}
      className={myClassName}
      to={props.to}
      {...props}
    >
      {props.children}
    </RouterNavLink>
  ) : (
    <a className={`${myClassName} ${styles.link}`} href={props.href} {...props}>
      {props.children}
    </a>
  )
}
