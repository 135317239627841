import Constants from './../../services/constants';

export const initialState = {
    address: "",
    referralAddress: "",
    referrers: {},
    referrals: {},
    isWeb3: false
};

export const rootReducer = (state = initialState, action) => {
    const data = action.data;
    switch (action.type) {
        case Constants.SET_ADDRESS:
        case Constants.SET_REFERRAL_ADDRESS:
        case Constants.SET_REFERRERS:
        case Constants.SET_IS_WEB3:
        case Constants.SET_REFERRALS:
            state = {
                ...state,
                ...data,
            };
            return {
                ...state
            };


        default:
            return state;
    }
};