import { Header } from 'components/navigation/Header'
import { Route, Switch } from 'react-router-dom'
import React, { useState } from 'react'
import { Container } from '.'
import { AppDrawer, AdminDrawer } from '.'
import styles from './PageLayout.module.scss'

export const PageLayout = props => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const handleDrawer = () => setOpenDrawer(!openDrawer)

  return (
    <div className={styles.layout}>
      <Switch>
        <Route path="/admin">
          <AdminDrawer active={openDrawer} mobile />
          <AdminDrawer />
        </Route>
        <Route>
          <AppDrawer active={openDrawer} mobile />
          <AppDrawer />
        </Route>
      </Switch>
      <Container isPadding={false} active={openDrawer}>
        <Header onDrawerButton={handleDrawer} active={openDrawer} />
        {props.children}
      </Container>
    </div>
  )
}
