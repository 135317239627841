import clsx from 'clsx'
import { useEffect, useState } from 'react'
import styles from './Sidebar.module.scss'

export function Sidebar({ className, onFilterChange }) {
  const [currentFilter, setCurrentFilter] = useState(null)

  useEffect(() => {
    onFilterChange?.(currentFilter)
  }, [currentFilter, onFilterChange])

  const handleSelectFilter = ev => {
    setCurrentFilter(ev.target.textContent)
  }

  const clearFilter = () => {
    setCurrentFilter(null)
  }

  return (
    <div className={`${className} ${styles.sidebar}`}>
      <div className={styles.header}>
        <p className={styles.title}>Фильтрация по направлению проекта:</p>
        <span className={styles.reset} tabIndex={0} onClick={clearFilter}>
          Cбросить
        </span>
      </div>
      <div className={styles.filters}>
        <button
          className={clsx(styles.button, {
            [styles.active]: currentFilter === 'Промышленность',
          })}
          type="button"
          onClick={handleSelectFilter}
        >
          Промышленность
        </button>
        <button
          className={clsx(styles.button, {
            [styles.active]: currentFilter === 'Медицина',
          })}
          type="button"
          onClick={handleSelectFilter}
        >
          Медицина
        </button>
        <button
          className={clsx(styles.button, {
            [styles.active]: currentFilter === 'IT',
          })}
          type="button"
          onClick={handleSelectFilter}
        >
          IT
        </button>
        <button
          className={clsx(styles.button, {
            [styles.active]: currentFilter === 'Недвижимость',
          })}
          type="button"
          onClick={handleSelectFilter}
        >
          Недвижимость
        </button>
      </div>
      <div className={styles.bottom}>
        <span className={styles.reset} tabIndex={0} onClick={clearFilter}>
          Cбросить
        </span>
      </div>
    </div>
  )
}
