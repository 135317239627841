import React, { useState } from 'react'
import { NavLink } from '../navigation/NavLink'
import { UserPanel } from '../navigation/UserPanel'
import styles from './Drawer.module.scss'
import logo from '../../assets/images/logo-text.svg'
import { useAuth } from 'hooks/useAuth'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ChevronIcon } from 'components/icons'

export const Drawer = ({ mobile = false, ...props }) => {
  const { user, logout } = useAuth()
  const isActive = props.active ? styles.containerActive : ''

  return (
    <nav
      className={`${styles.navigation} ${
        mobile ? styles.containerMobile : styles.containerPc
      } ${isActive}`}
    >
      <a className={styles.logoLink} href="https://myfuture.company">
        <img src={logo} className={styles.logo} />
      </a>
      {props.children({ user, logout })}
    </nav>
  )
}

export const AdminDrawer = props => {
  return (
    <Drawer {...props}>
      {({ user, logout }) => (
        <>
          <NavLink to="/admin/users">Пользователи</NavLink>
          <NavLink to="/admin/analytics">Аналитика</NavLink>
        </>
      )}
    </Drawer>
  )
}

export const AppDrawer = props => {
  const [opened, setOpened] = useState(false)

  const isWeb3 = useSelector(state => state.isWeb3)
  const address = useSelector(state => state.address)

  return (
    <Drawer {...props}>
      {({ user, logout }) => {
        return (
          <>
            <NavLink
              type="navigation"
              myActiveStyle={true}
              to="/"
              className={styles.navLinkFirst}
            >
              Проекты
            </NavLink>
            <NavLink
              target="_blank"
              type="navigation"
              myActiveStyle={true}
              href="https://myfuture.company/ventury-market"
              className={styles.navLink}
            >
              Ventury market
            </NavLink>
            <NavLink
              target="_blank"
              className={styles.navLink}
              href="https://myfuture.company/news"
            >
              Новости
            </NavLink>
            {/* <NavLink
              target="_blank"
              href="https://myfuture.company/about/investors">
              Для инвесторов
            </NavLink>
            <NavLink target="_blank" href="https://myfuture.company/guarantee">
              Гарантии
            </NavLink> */}

            {/* <NavLink
              className={styles.navLink}
              target="_blank"
              href="https://www.myfuture.company/instruction"
            >
              Инструкции
            </NavLink> */}
            <NavLink className={styles.navLink} to="/referal">
              Реферальная система
            </NavLink>
            <div className={styles.navLink}>
              <button
                className={`${styles.expandButton} ${
                  opened ? styles.expandActive : ''
                }`}
                onClick={() => setOpened(!opened)}
              >
                <span>Для инвесторов</span>
                <ChevronIcon className={styles.expandIcon} />
              </button>
              {opened && (
                <div className={styles.submenu}>
                  <NavLink
                    target="_blank"
                    className={styles.sublink}
                    href="https://myfuture.company/guarantee"
                  >
                    Гарантии инвестора
                  </NavLink>
                  <NavLink
                    target="_blank"
                    className={styles.sublink}
                    href="https://myfuture.company/instruction"
                  >
                    Инструкции и F.A.Q.
                  </NavLink>
                </div>
              )}
            </div>
            <NavLink
              className={styles.navLink}
              target="_blank"
              href="https://myfuture.company/business"
            >
              Бизнесу
            </NavLink>
            <NavLink
              className={styles.navLinkLast}
              target="_blank"
              href="https://www.myfuture.company/support"
            >
              Поддержка
              <br />
              <span className={styles.support}>Доступна 24/7</span>
            </NavLink>

            {user && (
              <UserPanel user={user} logout={logout} address={address} />
            )}
          </>
        )
      }}
    </Drawer>
  )
}
