import styles from './InvestChoiceStep.module.scss'
import clsx from 'clsx'
import { InvestSteps } from '.'

export function InvestAcceptStep({ count, onChangeStep }) {
  return (
    <div className={styles.block}>
      <div className={styles.top}>
        <span className={styles.step}>шаг 2</span>
        <span className={styles.name}>Ознакомьтесь с условиями</span>
      </div>
      <div className={styles.confirmation}>
        <p className={styles.confirmationText}>
          Я подтверждаю, что являюсь совершеннолетним и дееспособным
          гражданином. В настоящее время не нахожусь в состоянии алкогольного
          или наркотического опьянения. В полном объеме осознаю все риски
          венчурного инвестирования в данный проект. Мною изучены и поняты
          договор инвестирования и решение об эмиссии цифровых финансовых
          активов проекта
        </p>
      </div>
      <button className={styles.button} onClick={() => onChangeStep(2)}>
        Подтверждаю
      </button>
      <button
        className={clsx(styles.button, styles.rejectButton)}
        onClick={() => onChangeStep(0)}
      >
        Отклонить
      </button>
      <InvestSteps className={styles.steps} length={count} current={1} />
    </div>
  )
}
