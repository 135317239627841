import { PageLayout } from 'components/layout'
import { useUsersCollection } from 'hooks/useUsersCollection'
import { useEffect, useState } from 'react'
import { NavLink, Route, useRouteMatch } from 'react-router-dom'
import { transactionsCollection } from 'services/firebase'
import styles from './Analytics.module.scss'

const TOKENS = ['TNWG', 'TMON', 'TCKP', 'TNFM']

export const Analytics = props => {
  const match = useRouteMatch()
  const [transactions, setTransactions] = useState({
    TNWG: [],
    TMON: [],
    TCKP: [],
    TNFM: [],
  })

  useEffect(() => {
    transactionsCollection.get().then(docs =>
      docs.forEach(doc =>
        setTransactions(state => ({
          ...state,
          [doc.id]: doc.data().transactions,
        }))
      )
    )
  }, [])

  return (
    <PageLayout>
      <h1>
        Аналитика <p>Данные по покупке токенов</p>
      </h1>
      {TOKENS.map(token => (
        <NavLink
          activeClassName={styles.active}
          className={styles.link}
          key={token}
          to={`${match.path}/${token}`}
        >
          <h2>{`Транзакции ${token} - ${transactions[token].length}`}</h2>
        </NavLink>
      ))}
      <Route
        path={`${match.path}/:token`}
        render={({ match }) => (
          <TransactionsList token={transactions[match.params.token]} />
        )}
      />
    </PageLayout>
  )
}

const TransactionsList = ({ token }) =>
  token.length ? (
    <>
      {token.map(item => (
        <div className={styles.column}>
          <div className={styles.row}>
            <span>Дата</span>
            <span>Количество</span>
            <span>Email</span>
            <span>etherscan</span>
          </div>
          <div className={styles.row}>
            <span>{new Date(item.timestamp).toLocaleDateString()}</span>
            <span>{item.amount}</span>
            <span>{item.user.email}</span>
            <span>
              {!item.user.hash ? (
                <a
                  href={`https://etherscan.io/tx/${item.user.hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Ссылка
                </a>
              ) : (
                '==============='
              )}
            </span>
          </div>
        </div>
      ))}
    </>
  ) : (
    <h2>Нет транзакций</h2>
  )
