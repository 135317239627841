import React from "react"
import styles from "./TextInput.module.scss"

export const TextInput = React.forwardRef((props, ref) =>  {
  return !props.label ? (
    <input
      className={props.error ? styles.inputError : styles.input}
      type={props.type || "text"}
      value={props.value}
      id={props.name}
      ref={ref}
      {...props}
    />
  ) : (
    <label className={styles.label}>
      {props.label}
      <input
        className={props.error ? styles.inputError : styles.input}
        type={props.type || "text"}
        value={props.value}
        name={props.name}
        id={props.name}
        placeholder={props.placeholder}
        required={props.required}
        ref={ref}
        {...props}
      />
    </label>
  )
})
