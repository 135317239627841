import styles from './InvestChoiceStep.module.scss'
import clsx from 'clsx'
import { InvestSteps, InvestImportant } from '.'
// import qrcode from 'assets/images/qrcode.png'
import qrcode from 'assets/images/qr_tale.jpg'
import { QRCodeSVG } from 'qrcode.react'
import { ExclameIcon } from 'components/icons'

export function InvestFiat({ count, onChangeStep }) {
  return (
    <div className={styles.block}>
      <div className={styles.top}>
        <span className={styles.step}>шаг 3</span>
        <div className={styles.stepName}>
          <div className={styles.important}>
            <span className={styles.name}>Инвестиции в FIAT(е)</span>
            <span className={styles.importantText}>ВАЖНАЯ ИНФОРМАЦИЯ! ↓</span>
          </div>
          <span className={styles.camera}>
            <ExclameIcon className={styles.exclameIcon} />
            Наведите камеру на QR Код
          </span>
        </div>
      </div>
      <div className={styles.qrcodeWrapper}>
        <a
          className={styles.qrcode}
          href="https://qr.nspk.ru/AS1A0017CU4IAMH29L58FUT5GO5A7GPL?type=01&bank=100000000111&crc=628D"
          target="_blank"
          rel="noreferrer"
        >
          {/* Захардкожен qr для TALE */}
          <QRCodeSVG value="https://qr.nspk.ru/AS1A0017CU4IAMH29L58FUT5GO5A7GPL?type=01&bank=100000000111&crc=628D" />
          {/* <img className={styles.image} src={qrcode} alt="" /> */}
        </a>
        <button className={clsx(styles.button, styles.buttonHidden)}>
          Инвестировать
        </button>
      </div>
      <div className={styles.project}>
        <p className={styles.sumText}>
          Минимальная сумма инвестиций <br /> в проект составляет{' '}
          <span className={styles.sumGreen}>2000 $</span>
        </p>
        <p className={styles.sumDesc}>
          При меньшей стоимости инвестирования, денежные средства будут
          возвращены Вам за минусом банковских комиссий за транзакции
        </p>
      </div>
      <InvestImportant className={styles.importantBlock} />
      <InvestSteps className={styles.steps} length={count} current={2} />
    </div>
  )
}
