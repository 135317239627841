import React from 'react'
import styles from './InfoLink.module.scss'
import { ExclameIcon } from 'components/icons'

export function InfoLink({ children, className }) {
  return (
    <a
      className={`${styles.link} ${className}`}
      href="https://myfuture.company/check"
      target="_blank"
    >
      <ExclameIcon className={styles.icon} />
      <span className={styles.name}>{children}</span>
    </a>
  )
}
