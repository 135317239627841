import React from "react"
import styles from "./Loading.module.scss"
import logo from "assets/images/logo-small.svg"
import { Button } from "components/form"
import { SupportHeader } from "./SupportHeader"
import { Container } from "./Container"

export const Loading = props => {
  return (
    <div className={styles.container}>
      <img src={logo} alt=" " className={styles.img} />
      <h1>My future</h1>
    </div>
  )
}

export const PendingKYC = props => {
  return (
    <Container>
      <SupportHeader />
      <div className={styles.container2}>
        <img src={logo} alt=" " className={styles.img} />
        <h1>Запрос на подтверждение KYC отправлен</h1>
        <p>Ожидайте ответ на почту указаную при регистрации</p>
        {/* <div>{props.email}</div> */}
        <Button
          onClick={() => {
            document.location.reload()
          }}>
          Обновить
        </Button>
        <p>
          Для покупки ЦФА и использования реферальной ссылки Вам потребуется
          Metamask Wallet
        </p>
        <a href="https://myfuture.company/metamask">Как установить Metamask?</a>
      </div>
    </Container>
  )
}
