import React from "react"
import styles from "./Header.module.scss"
import cross from "../../assets/images/cross.svg"
import { Link } from "react-router-dom"

export const Header = ({ onDrawerButton, active, ...props }) => {
  return (
    <header className={styles.container}>
      <button onClick={onDrawerButton}>
        {active ? (
          <img src={cross} />
        ) : (
          <>
            <span></span>
            <span></span>
          </>
        )}
      </button>

    </header>
  )
}
