import { Container, Tag } from 'components/layout'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  PageLayout,
  Sidebar,
  ProjectSidebar,
  ProjectMain,
} from 'components/layout'
import { InvestModal } from 'components/modals'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { buyToken } from 'services/Web3/Web3'
import cross from '../assets/images/cross.svg'
import { Button } from '../components/form/Button'
import { TextInput } from '../components/form/TextInput'
import { SupportHeader } from '../components/layout'
import { NavLink } from '../components/navigation/NavLink'
import styles from './ProjectPage.module.scss'
import monarchImg from 'assets/images/Monarch.png'
import CKPImg from 'assets/images/CKP.png'
import networkgramImg from 'assets/images/Networkgram.png'
import newformatImg from 'assets/images/New_format.png'
import { useAuth } from 'hooks/useAuth'
import Modal from 'react-modal'
import projects from 'services/projects.json'

const ProjectPage = props => {
  const location = useLocation()
  const history = useHistory()
  const [popup, setPopup] = useState(false)
  const [token, setToken] = useState('')
  const [isModal, setModal] = useState(false)
  const { user } = useAuth()

  const handleBuyTokenPopup = () => {
    if (user?.kyc_state === 'passed') setPopup(!popup)
    else history.push('/know-your-customer')
  }

  const handleOpenModal = () => {
    setModal(true)
  }

  const handleCloseModal = () => {
    setModal(false)
  }

  const data = projects[token] || {}

  useEffect(() => {
    const currentToken = location.pathname.substring(9)
    setToken(currentToken)
  }, [user])

  return (
    <PageLayout>
      <div className={styles.wrapper}>
        <ProjectMain
          className={styles.main}
          data={data}
          token={token}
          handleBuyTokenPopup={handleBuyTokenPopup}
          onOpenModal={handleOpenModal}
        />
        <ProjectSidebar className={styles.sidebar} />
      </div>
      <InvestModal
        address={props.address}
        token={token}
        isModal={isModal}
        onCloseModal={handleCloseModal}
        price={data.price}
        fiatDisabled={data.fiatDisabled}
        buyDisabled={data.buyDisabled}
      />
      {/* <CSSTransition in={popup} timeout={333} unmountOnExit mountOnEnter>
        {state => (
          <Popup
            referrer={referrerAddress}
            address={props.address}
            token={token}
            transitions={state}
            closePopup={() => setPopup(false)}
          />
        )}
      </CSSTransition> */}
    </PageLayout>
  )
}

const Popup = ({ closePopup, transitions, address, token, referrer }) => {
  const [amount, setAmount] = useState(0)
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  const data = json[token] || {}

  const addAmount = () => {
    setAmount(amount + data.price)
  }
  const removeAmount = () => {
    setAmount(amount - data.price)
  }

  const handleMakeTransaction = () => {
    setModal(true)

    buyToken(address, amount, token, referrer)
      .then(() => {
        setModal(false)
      })
      .catch(() => {
        setModal(false)
      })
  }
  return (
    <div className={`${styles.popupMain} ${styles[transitions]}`}>
      <Link onClick={closePopup}>
        <img src={cross} />
      </Link>
      <div className={styles.popupHead}>
        <h2>Покупка ЦФА</h2>
        <Tag color="#002143">{token}</Tag>
      </div>
      <h6>Проект</h6>
      <div className={styles.popupInfoContent}>
        <h4>{data.title}</h4>
        {/* <small>Virgin Galactic</small> */}
        <div
          className={styles.upheader}
          style={{
            marginTop: '20px',
          }}
        >
          <Tag color="#fff">{data.category}</Tag>
          <dd>Размещение: </dd>
          <dt>{data.date}</dt>
        </div>
      </div>
      <div className={styles.popupText}>
        <p>{data.helperText}</p>
      </div>
      <div className={styles.popupBuyBlock}>
        <div>
          <h5>Стоимость 1 ЦФА = {data.price}$</h5>
          <h6>Введите количество USDT кратное {data.price} для покупки ЦФА</h6>
          <form>
            <div style={{ display: 'flex', alignItems: 'stretch' }}>
              <Button type="button" onClick={removeAmount}>
                -
              </Button>
              <TextInput
                value={amount}
                onChange={({ target }) => setAmount(target.value)}
              />
              <Button
                type="button"
                style={{ marginTop: '0' }}
                onClick={addAmount}
              >
                +
              </Button>
            </div>
            <Button
              type="button"
              style={{ marginBottom: '50px' }}
              onClick={handleMakeTransaction}
            >
              Совершить транзакцию
            </Button>
          </form>
        </div>
      </div>
      <CSSTransition in={modal} timeout={0} unmountOnExit mountOnEnter>
        {state => (
          <div
            className={`${styles.spinner_container} ${styles[state]} ${styles.fullWidth}`}
          >
            <div className={styles.asd}>
              <Spinner />
            </div>
          </div>
        )}
      </CSSTransition>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    address: state.address,
    referralAddress: state.referralAddress,
    referrers: state.referrers,
  }
}

export default connect(mapStateToProps, null)(ProjectPage)

const Spinner = props => <div className={styles.spinner}></div>

const json = {
  TMON: {
    token: 'TMON',
    category: 'Промышленность',
    rating: '9',
    title: 'Monarch',
    dfaType: 'Security',
    roi: 'до 200',
    date: '07.07.2021',
    dynamics: 'Высокий рост',
    markets: 'РФ, СНГ, Европа',
    availableTokens: '35 000',
    img: monarchImg,
    knowMore: 'https://myfuture.company/project/monarch',
    bigText: `MONARCH – проект по созданию электромобилей нового поколения.
    Мы убеждены в том, что транспорт
    с электродвигателями – это будущее.

    Десятки инженеров, дизайнеров и программистов собрались в одной компании, чтобы разрабатывать электромобили нового поколения.

    Идеология чистой планеты и светлого будущего
    для новых поколений — двигатель для создателей
    и новаторов команды MONARCH!
    `,
    price: 100,
    helperText: `Данный актив имеет класс «security» и предусматривает получение денежных средств для его владельца от лица компании эмитента или получения ценных бумаг - будущих акций компании (зависит от выбора владельца) в течении 4 месяцев после: слияния компаний размещённой на NASDAQ с ООО «Новые технологии Сибири» - технология SPAC, получения (изменения) оценочной стоимости компании,  эмиссии акций дивидендной доходности и размещения их на ОТС Market NASDAQ.

Коэффициент доходности для инвестора составит +50%, +100% и +200% в зависимости от объема инвестированных средств - до 10 000$ USA, от 10 001$ USA и до 100 000$ USA и от 100 001$ USA и до 1000 000$ USA соответственно.

При объеме инвестирования свыше 1 000 000$ - возможны индивидуальные условия.

Предусмотрено, что акции компании, полученной после слияния SPAC, будут выпущены общим объемом 300 000 000 шт., по цене 10$ за 1 акцию, общей стоимостью 3 000 000 000 долларов США (возможна коррекция в зависимости от оценочной стоимости) и будут размещены в свободной продаже на ОТС Market NASDAQ в количестве 49% от общего объема.

Вид – будущих акции на ОТС Market NASDAQ – акция дивидендной доходности.

Цифровые финансовые активы выпущены компанией ООО «Новые технологии Сибири». Вид цифровой платформы – ethereum, протокол ERC20.

Компанией ООО «Новые технологии Сибири» эмитировано
35 000  ЦФА (token security). Стоимость 1 ЦФА = 100$ USA. Всего на 3 500 000$ USA.

Предварительное распределение по иным сделкам и обязательствам (airdrop) не предусмотрено.

Название токена: «Token Monarch Company».
Биржевой тикет «TMNC» (далее по тексту – «TMNC»).
Дата начала эмиссии TMNC – 15.05.2021г.

Способы приобретения TMNC – доллар, евро, юань, рубль,  USDT (автоматическая покупка/продажа).

Договор публичной оферты при первоначальном приобретении – обязателен. Дальнейшее подтверждение права – право доступа к хранению на wallet ERC20 или иному источнику хранения криптографических данных.

Обеспечением исполнения обязанностей по TMNC является все движимое и недвижимое имущество ООО «Новые технологии Сибири», а также новой компании, полученной после слияния SPAC на NASDAQ, находящееся на их балансе. Исключением являются интеллектуальные права на разработки и патентные права
`,
  },
  TCKP: {
    token: 'TCKP',
    category: 'Медицина',
    rating: '9',
    title: 'CKP',
    dfaType: 'Security',
    roi: 'до 90',
    date: '07.07.2021',
    dynamics: 'Средний рост',
    markets: 'Не ограничено',
    availableTokens: '30 200',
    img: CKPImg,
    knowMore: ' https://myfuture.company/project/ckp',
    bigText: `Рак – страшная болезнь. Кто-то из Ваших родственников, друзей или знакомых обязательно сталкивался с этим недугом. Но рак излечим! Доказано, что прямое воздействие холодной плазменной струи вызывает гибель опухолевых клеток. Возможность использования разнообразных режимов воздействия на инертные газы, в том числе и на их смеси с молекулярными газами, в применяемом газоразрядном устройстве позволяет генерировать холодную плазменную струю с широким набором энергетических параметров и компонентным составом.
    `,
    price: 100,
    helperText: `Цифровые финансовые активы выпущены компанией LLC «Сancer-killing plasma». Вид цифровой платформы – ethereum, протокол ERC20.

Данный актив имеет класс «security» предусматривает для его владельца возможность получения денежных средств от лица компании эмитента.

Коэффициент доходности для инвестора составит до 50% годовых при условии положительного проведения клинических испытаний, запуска 60 центров приема больных к 2023г (прочие показатели отражены в экономической модели проекта).

Компанией ООО «Сancer-killing plasma» эмитировано 30 200  ЦФА (token) по первоначальной стоимости 100$ за 1 ЦФА – то есть всего на 3 020 000$ USA.

Предварительное распределение по иным сделкам и обязательствам (airdrop) не предусмотрено.

Название токена: «Token Сancer-killing plasma».

Биржевой тикет «TCKP» (далее по тексту – «TCKP»).

Дата начала эмиссии TCKP – 15.06.2021г.

Способы приобретения TNVF – доллар, евро, юань, рубль,  USDT (автоматическая покупка/продажа).

Договор публичной оферты при первоначальном приобретении – обязателен. Дальнейшее подтверждение права – право доступа к хранению на wallet ERC20 или иному источнику хранения криптографических данных.

Обеспечением исполнения обязанностей по TCKP является все движимое и недвижимое имущество LLC «Сancer-killing plasma» за исключением интеллектуальных прав и патентных разработок.
`,
  },
  TNFM: {
    token: 'TNFM',
    category: 'Строительство',
    rating: '8',
    title: 'Новый формат',
    dfaType: 'Security/Utility',
    roi: 'до 20',
    date: '07.07.2021',
    dynamics: 'Средний рост',
    markets: 'РФ, СНГ',
    availableTokens: '34 000',
    img: newformatImg,
    knowMore: ' https://myfuture.company/project/newformat',
    bigText: `Новый формат – смелый и амбициозный проект современной России! Пандемия коронавируса навсегда изменила наш мир. Особенно это коснулось формата жизни и работы граждан. Все больше людей отказываются от постоянного места жительства и ипотеки, выбирая свободу.`,
    price: 100,
    helperText: `Цифровые финансовые активы выпущены компанией ООО «Новый формат». Вид цифровой платформы – ethereum, протокол ERC20.

Данный актив имеет класс «security» и «utility» одновременно и предусматривает для его владельца: возможность использования объекта недвижимости (проживание в нем до 4 человек) в зависимости от объема инвестиций, а также получение денежных средств для его владельца от лица компании эмитента.

Коэффициент доходности для инвестора составит в районе 20% годовых при условии 80% годового заполнения и стоимости проживания в объекте 3 472 рубля в сутки.
Компанией ООО «Новый формат» эмитировано 34 200  ЦФА (token) по первоначальной стоимости 100$ за 1 ЦФА – то есть всего на 3 420 000$ USA.

Предварительное распределение по иным сделкам и обязательствам (airdrop) не предусмотрено.
Название токена: «Token new format».

Биржевой тикет «TNVF» (далее по тексту – «TNVF»).
Дата начала эмиссии TNVF – 15.06.2021г.

Способы приобретения TNVF – доллар, евро, юань, рубль,  USDT (автоматическая покупка/продажа).

Договор публичной оферты при первоначальном приобретении – обязателен. Дальнейшее подтверждение права – право доступа к хранению на wallet ERC20 или иному источнику хранения криптографических данных.

Обеспечением исполнения обязанностей по TNVF является все движимое и недвижимое имущество ООО «Новый формат».`,
  },
  TNWG: {
    token: 'TNWG',
    category: 'IT',
    rating: '8',
    title: 'Networkgram',
    dfaType: 'Security/Utility',
    roi: 'до 100',
    date: '07.07.2021',
    dynamics: 'Средний рост',
    markets: 'Не ограничено',
    availableTokens: '26 500',
    img: networkgramImg,
    knowMore: ' https://myfuture.company/project/networkgram',
    bigText: `NETWORKGRAM – гибрид AMAZON и INSTAGRAM. Социальная сеть, где за рекламу товара – платят Вам!

Вся прибыль за продвижение товара распределяется по сети (MLM) между участниками твоей команды. NETWORKGRAM: если есть команда – любой товар бесплатно. Идеальный продукт для людей имеющих команду! NETWORKGRAM – новая мировая социальная сеть.
`,
    price: 10,
    helperText: `Цифровые финансовые активы выпущены компанией ООО «Нетворкграм». Вид цифровой платформы – ethereum, протокол ERC20.

Данный актив имеет класс «security» и предусматривает получение денежных средств для его владельца от лица компании эмитента путём прямого обратного выкупа + коэффициент доходности.
Коэффициент доходности для инвестора составит +50% и +100% в зависимости от времени предъявления обратного выкупа и рассчитан при обороте компании 887 250 000 рублей за период 2021, 2022 и 2023 годов.

Адрес смарт-контракта: будет указан после 15.06.2021г

Компанией ООО «Нетворкграм» эмитировано 26 500  ЦФА (token security) по первоначальной стоимости 10$ за 1 ЦФА – то есть всего на 265 000 $ USA.

Предварительное распределение по иным сделкам и обязательствам (airdrop) не предусмотрено.

Название токена: «Token Networkgram».

Биржевой тикет «TNWG» (далее по тексту – «TNWG»).

Дата начала эмиссии TCKP – 15.06.2021г.

Способы приобретения TNWG – доллар, евро, юань, рубль,  USDT (автоматическая покупка/продажа).

Договор публичной оферты при первоначальном приобретении – обязателен. Дальнейшее подтверждение права – право доступа к хранению на wallet ERC20 или иному источнику хранения криптографических данных.

Обеспечением исполнения обязанностей по TNWG является все движимое и недвижимое имущество ООО «Нетворкграм», а также программные продукты, полученные за счет средств инвесторов за исключением и патентных прав.
`,
  },
}
