import styles from './InvestChoiceStep.module.scss'
import clsx from 'clsx'
import { InvestSteps } from '.'

export function InvestChoiceStep({
  choice,
  count,
  onChangeChoice,
  onChangeStep,
  fiatDisabled,
  token,
  buyDisabled,
}) {
  const handleClick = ev => {
    if (fiatDisabled && ev.target.value === 'FIAT') {
      return window.location.assign(`https://myfuture.company/fiat/${token}`)
    }
    if (buyDisabled) {
      return window.location.assign(`https://myfuture.company/${token}`)
    }
    onChangeChoice(ev.target.value)
  }
  return (
    <>
      <div className={styles.block}>
        <div className={styles.top}>
          <span className={styles.step}>шаг 1</span>
          <span className={styles.name}>Выберите способ инвестирования</span>
        </div>
        <div className={styles.choices}>
          <button
            className={clsx(
              styles.choiceButton,
              choice === 'FIAT' && styles.choiceButtonActive
            )}
            value="FIAT"
            onClick={handleClick}
          >
            FIAT
          </button>
          <button
            className={clsx(
              styles.choiceButton,
              choice === 'USDT' && styles.choiceButtonActive
            )}
            value="USDT"
            onClick={handleClick}
          >
            USDT
          </button>
        </div>
        {!buyDisabled && choice === 'USDT' && (
          <button className={styles.button} onClick={() => onChangeStep(1)}>
            Далее
          </button>
        )}
        {!buyDisabled && choice === 'FIAT' && !fiatDisabled && (
          <button className={styles.button} onClick={() => onChangeStep(1)}>
            Далее
          </button>
        )}
      </div>
      <InvestSteps className={styles.steps} length={count} current={0} />
    </>
  )
}
