import React, { useEffect, useState, Fragment, useMemo } from 'react'
import styles from './AdminUsers.module.scss'
import { getUsersCollection, setUserData } from 'services/firebase'
import { formatDate } from 'utils'
import { Button } from 'components/form'
import { useUsersCollection } from 'hooks/useUsersCollection'
import { useAuth } from 'hooks/useAuth'
import { PageLayout } from 'components/layout'
import arrow from '../../assets/images/right-arrow.png'
import { toast } from 'react-toastify'

const filterLocalization = {
  passed: 'Прошли проверку',
  not_started: 'Не начали проверку',
  started: 'Ждут проверку',
  users: 'Все',
}

export const AdminUsers = props => {
  const { users, getUsers } = useUsersCollection()
  const [filter, setFilter] = useState('users')
  const [sort, setSort] = useState('ASC')

  const filteredUsers = useMemo(() => {
    return {
      users: users,
      started: users.filter(user => user.kyc_state === 'started'),
      passed: users.filter(user => user.kyc_state === 'passed'),
      not_started: users.filter(user => user.kyc_state === 'not_started'),
    }
  }, [users])

  const handleFilter = ({ currentTarget }) => setFilter(currentTarget.name)
  const handleSort = () => {
    if (sort === 'ASC') setSort('DESC')
    else setSort('ASC')
  }

  return (
    <PageLayout>
      <div>
        <h1 className={styles.header}>
          Пользователи{' '}
          <span>
            Сортировать по дате{' '}
            <img
              onClick={handleSort}
              className={sort === 'ASC' && styles.active}
              src={arrow}
              alt=""
            />
          </span>
        </h1>
        <ul className={styles.tabs}>
          {Object.entries(filteredUsers).map(([name, value]) => (
            <button
              className={filter === name && styles.active}
              name={name}
              key={name}
              onClick={handleFilter}
            >{`${filterLocalization[name]} ${value.length}`}</button>
          ))}
        </ul>
      </div>
      <List data={filteredUsers[filter]} sort={sort}>
        {item => (
          <AdminUsersItem key={item.email} data={item} getUsers={getUsers} />
        )}
      </List>
    </PageLayout>
  )
}

const userFieldsLocalization = {
  id: 'ID',
  phone: 'Тел.',
  email: 'Email',
  fio: 'ФИО',
  passportDate: 'Дата выдачи паспорта',
  dobDate: 'Дата рождения',
  birthplace: 'Место рождения',
  referalNumber: 'Пригласил',
  passport: 'Паспорт',
  timestamp: 'Дата старта KYC',
}

const AdminUsersItem = props => {
  const { sendMail } = useAuth()
  const {
    id,
    phone,
    email,
    first_name,
    last_name,
    third_name,
    passport_date = '0000-00-00',
    passport_number,
    passport_code,
    passport_images,
    dob = '0000-00-00',
    birthplace,
    kyc_state,
    referalNumber,
    timestamp,
  } = props.data

  const dobDate = formatDate(dob)
  const passportDate = formatDate(passport_date)

  const visibleData = {
    id,
    phone,
    email,
    fio: [last_name, first_name, third_name].join(' '),
    passportDate,
    dobDate,
    birthplace,
    referalNumber,
    passport: [passport_number, passport_code].join(' '),
    timestamp: timestamp && formatDate(timestamp),
  }

  const handleApprove = () => {
    setUserData({ uid: id }, { kyc_state: 'passed' }).then(() => {
      sendMail('kyc_passed', { uid: id, first_name })
      props.getUsers()
    })
  }

  const handleDismiss = () => {
    setUserData({ uid: id }, { kyc_state: 'not_started' }).then(() => {
      sendMail('kyc_declined', { uid: id, first_name })
      props.getUsers()
    })
  }

  const STATE = {
    passed: 'Прошел проверку',
    started: 'Ждет проверки',
    not_started: 'Не начал проверку',
  }
  const copyLink = ({ currentTarget }) => {
    if (!currentTarget.value) return
    currentTarget.select()
    toast.success(`Скопировано ${currentTarget.value}`)
    document.execCommand('copy')
  }
  return (
    <section
      onClick={props.onClick}
      className={`${styles.itemContainer} ${styles[kyc_state]}`}
    >
      <div className={styles.images}>
        {passport_images?.length ? (
          passport_images.map(url => (
            <a href={url} target="_blank" rel="noreferrer">
              <img src={url} alt="" />
            </a>
          ))
        ) : (
          <h2>Нет фото</h2>
        )}
      </div>
      {kyc_state && <h5>{STATE[kyc_state]}</h5>}
      <div className={styles.user_fields}>
        {Object.entries(visibleData).map(([name, value], index) => (
          <div className={!value && styles.error}>
            <label htmlFor={name}>{userFieldsLocalization[name]}</label>
            <input
              id={`${name}-${id}`}
              type="text"
              readOnly
              name={name}
              defaultValue={value}
              onClick={copyLink}
            />
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <Button onClick={handleApprove}>Подтвердить</Button>
        <Button type="red" onClick={handleDismiss}>
          Отклонить
        </Button>
      </div>
    </section>
  )
}

const List = ({ data = [], children, sort }) => {
  const timestampComparator = sort => (a, b) =>
    sort === 'DESC'
      ? new Date(a.timestamp) - new Date(b.timestamp)
      : new Date(b.timestamp) - new Date(a.timestamp)

  const sortedData = useMemo(() => {
    return {
      ASC: data.sort(timestampComparator(sort)),
      DESC: data.sort(timestampComparator(sort)),
    }
  }, [data, sort])
  return (
    <ul className={styles.listContainer} hidden>
      {sortedData[sort].map((item, index) => {
        return children ? (
          <Fragment key={item.id}>{children(item)}</Fragment>
        ) : (
          <span key={item.id}>{item}</span>
        )
      })}
    </ul>
  )
}
