import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  createUserDocument,
  firebaseAuth,
  FirestoreEntity,
  mailCollection,
  UserEntity,
  usersCollection,
} from 'services/firebase'
import { getISODate } from 'utils'

export const useAuth = () => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (firebaseAuth.currentUser) {
      getUserData(firebaseAuth.currentUser)
    }

    firebaseAuth.onAuthStateChanged(authUser => {
      if (authUser) {
        getUserData(authUser)
      } else setUser(false)
    })
  }, [])

  const getUserData = user => {
    usersCollection
      .doc(user.uid)
      .get()
      .then(doc => setUser({ ...user, ...doc.data() }))
  }
  const updateUserData = async data => {
    await usersCollection.doc(user.uid).update(data)

    return true
  }
  const signOut = () => {
    firebaseAuth.signOut()
  }

  const resetPassword = (email, onSuccess = () => {}, onFail = () => {}) =>
    firebaseAuth
      .sendPasswordResetEmail(email)
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        const { code, message } = error
        switch (code) {
          case 'auth/user-not-found':
            onFail({ email: 'Пользователь не найден.' })
            break
          case 'auth/invalid-email':
            onFail({
              email:
                'Проверьте правильность написания почты.(пример: example@gmail.com).',
            })
            break

          default:
            onFail(error)
            break
        }
      })

  const createNewUser = async ({ email, password, referalNumber }) => {
    try {
      const createdUserDocument =
        await firebaseAuth.createUserWithEmailAndPassword(email, password)
      const userDocument = new UserEntity({
        id: createdUserDocument.user.uid,
        email: createdUserDocument.user.email,
        kyc_state: 'not_started',
        created_at: getISODate(),
        referalNumber,
      })

      await userDocument.createEntity()

      console.log(userDocument.data)

      return { userDocument }
    } catch (error) {
      const { code, message } = error
      switch (code) {
        case 'auth/email-already-in-use':
          return {
            errors: { email: 'Введенный вами e-mail уже используется.' },
          }
        case 'auth/invalid-email':
          return {
            errors: {
              email:
                'Проверьте правильность написания почты.(пример: example@gmail.com).',
            },
          }
        default:
          return { errors: error }
      }
    }
  }

  const login = (
    email,
    password,
    { onSuccess = () => {}, onFail = () => {} }
  ) => {
    return firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(userCredential => {
        setUser(userCredential.user)
        onSuccess(userCredential.user)
      })
      .catch(error => {
        const { code, message } = error
        console.log('Login fail', code, message)
        onFail(error)
      })
  }

  const sendMail = (template, toUser) => {
    mailCollection.add({
      toUids: [toUser.uid || user.uid],
      template: {
        name: template,
        data: {
          username: toUser.first_name || user.first_name,
        },
      },
    })
  }

  return {
    user,
    createNewUser,
    getUserData,
    resetPassword,
    updateUserData,
    login,
    sendMail,
    // modifyUsers,
    logout: signOut,
    authStateChanged: firebaseAuth.onAuthStateChanged,
  }
}
