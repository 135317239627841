import { useEffect, useState } from 'react'
import { getUsersCollection } from 'services/firebase'

export const useUsersCollection = (...query) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    getUsers(query)
  }, [])

  const handleResponse = response => {
    const result = []
    response.forEach(doc => result.push({ id: doc.id, ...doc.data() }))
    setUsers(result)
  }

  const getUsers = (query = []) =>
    getUsersCollection(...query).then(handleResponse)

  return { users, getUsers }
}
