import React from "react"
import { Redirect, Route } from "react-router-dom"

export const ProtectedRoute = ({ component: Component, condition, redirectTo, ...props }) => {

  return (
    <Route
      {...props}
      render={props =>
        condition ? <Component {...props} /> : <Redirect to={redirectTo || "/"}/>
      }
    />
  )
}
