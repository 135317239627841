import { useAuth } from 'hooks/useAuth'
import React, { useRef, useState } from 'react'
import { Button, TextInput } from 'components/form'
import { Container, HeadingRow, SupportHeader } from 'components/layout'
import styles from './Login.module.scss'
import { NavLink } from 'components/navigation/NavLink'
import { CitySelect } from 'components/elements'

export const Register = ({ referalId }) => {
  const { createNewUser } = useAuth()
  const [city, setCity] = useState('')
  const [cityOpened, setCityOpened] = useState(false)
  const [errors, setErrors] = useState({})
  const handleSuccessRegister = () => {}
  const formRef = useRef(null)
  const cityRef = useRef(null)

  const handleErrors = error => {
    setErrors(state => ({ ...state, ...error }))
  }

  const handleChange = ev => {
    const { password, password_repeat } = ev.currentTarget

    if (password.value === password_repeat.value && errors.password)
      setErrors(state => {
        delete state.password
        return state
      })
  }

  const handleChooseCity = name => {
    const { city } = formRef.current

    city.value = name
    setCity(name)
    setCityOpened(false)
  }

  const handleSubmit = async formEvent => {
    formEvent.preventDefault()
    formEvent.stopPropagation()
    const { email, password, password_repeat, city } = formEvent.currentTarget

    console.log('CITY', city.value)

    if (password.value !== password_repeat.value)
      return setErrors(state => ({
        ...state,
        password: 'Пароли должны совпадать',
      }))

    const { userDocument, errors } = await createNewUser({
      email: email.value,
      password: password.value,
      referalNumber: referalId,
    })

    handleErrors(errors)
  }

  return (
    <Container>
      <div className={styles.container}>
        <SupportHeader logo backTo={'/login'} />
        <HeadingRow
          className="no-gap"
          heading={<h2 className={styles.heading}>Регистрация</h2>}
          subheading={
            <p className={styles.subheading}>
              Платформа покупки цифровых <br />
              финансовых активов / ЦФА
            </p>
          }
        />
        <form
          action="a"
          onSubmit={handleSubmit}
          ref={formRef}
          onChange={handleChange}
          onBlur={() => setErrors({})}
        >
          <TextInput
            required
            name={'email'}
            placeholder="Введите e-mail"
            error={errors.email}
          />
          {errors.email && (
            <div style={{ marginTop: '10px', color: '#c70000' }}>
              {errors.email}
            </div>
          )}
          <TextInput
            required
            type="password"
            name={'password'}
            placeholder="Введите пароль"
            error={errors.password}
          />
          <TextInput
            required
            type="password"
            name={'password_repeat'}
            placeholder="Введите такой же пароль"
            error={errors.password}
          />
          {errors.password && (
            <div style={{ marginTop: '10px', color: '#c70000' }}>
              {errors.password}
            </div>
          )}
          <div className={styles.cityBlock}>
            <CitySelect
              className={styles.cityWrapper}
              opened={cityOpened}
              search={city}
              onChooseCity={handleChooseCity}
              onCloseSelect={() => setCityOpened(false)}
            />
            <TextInput
              required
              type="text"
              name={'city'}
              placeholder="Введите город проживания"
              onChange={e => {
                setCity(e.target.value)
                setCityOpened(true)
              }}
              onFocus={() => {
                setCityOpened(true)
              }}
              error={errors.city}
              ref={cityRef}
            />
          </div>
          <Button type="blue">Зарегистрироваться</Button>
        </form>
        <NavLink
          className={styles.resetPass}
          style={{ marginTop: 'auto' }}
          href="https://myfuture.company/support"
          target="_blank"
        >
          ТЕХНИЧЕСКАЯ ПОДДЕРЖКА
        </NavLink>
        <small className={styles.version}>Версия 1.0.0</small>
      </div>
    </Container>
  )
}
