import styles from './InvestImportant.module.scss'
import clsx from 'clsx'
import { CopyIcon } from 'components/icons'
import { copyToClipboard } from 'utils'

export function InvestImportant({ className }) {
  const handleCopy = () => {
    copyToClipboard('invest@myfuture.company')
  }

  return (
    <div className={clsx(className, styles.modal)}>
      <div className={styles.top}>
        <p className={styles.important}>ВАЖНАЯ ИНФОРМАЦИЯ!</p>
        <p className={styles.text}>
          Обращаем особое внимание, что после инвестирования денежных средств,{' '}
          <span className={styles.bold}>
            необходимо направить чек об оплате
          </span>{' '}
          на электронную почту проекта
        </p>
        <p className={styles.title}>Электронная почта для отправки чека:</p>
        <div className={styles.email}>
          <span className={styles.emailName}>invest@myfuture.company</span>
          <CopyIcon className={styles.emailIcon} onClick={handleCopy} />
        </div>
      </div>
      <div className={styles.help}>
        <span className={styles.helpTitle}>Нужна помощь?</span>
        <span className={styles.helpText}>
          Напишите в{' '}
          <a className={styles.helpUnderline} href="/">
            Техническую поддержку
          </a>
        </span>
      </div>
    </div>
  )
}
