import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import { getISODate } from 'utils'

const firebaseConfig = {
  apiKey: 'AIzaSyCEzDZG6SZ-yX-17G4XPqeHAM1r-HuBN78',
  authDomain: 'andrew-8c343.firebaseapp.com',
  databaseURL: 'https://andrew-8c343.firebaseio.com',
  projectId: 'andrew-8c343',
  storageBucket: 'andrew-8c343.appspot.com',
  messagingSenderId: '650561653436',
  appId: '1:650561653436:web:1e62ba8a8bde4f219a1eb9',
}

firebase.initializeApp(firebaseConfig)

export const firebaseAuth = firebase.auth()
export const firestore = firebase.firestore()
export const storageRef = firebase.storage()

let currentUser = null
console.log()
firebaseAuth.onAuthStateChanged(user => {
  currentUser = user
})
// TODO: Вынести в сервисный слой
export const usersCollection = firestore.collection('users')
export const errorsCollection = firestore.collection('errors')
export const mailCollection = firestore.collection('mail')
export const transactionsCollection = firestore.collection('transactions')

export const addTransaction = (token, amount, etherscan) =>
  transactionsCollection.doc(token).set({
    transactions: [
      {
        timestamp: getISODate(),
        amount,
        user: { id: currentUser.uid, email: currentUser.email, hash: etherscan },
      },
    ],
  })
export const getUserData = user => usersCollection.doc(user.uid).get()
export const setUserData = (user, data, merge) =>
  usersCollection.doc(user.uid).set(data, { merge: true })
export const getUsersCollection = (...query) => {
  return query.length
    ? usersCollection.where(...query).get()
    : usersCollection.get()
}
export const createUserDocument = userDocument => ({
  passedKyc: false,
  birthplace: '',
  dob: '',
  first_name: '',
  last_name: '',
  third_name: '',
  passport_code: '',
  passport_date: '',
  passport_number: '',
  // email: '',
  ...userDocument,
})
// const auth = firebase.auth()

export class FirestoreEntity {
  constructor(collectionName, data = { id: null }) {
    this.data = data
    this.collection = firestore.collection(collectionName)
  }

  async createEntity() {
    try {
      if (this.id) {
        await this.collection.doc(this.id).set(this.data)
      } else {
        await this.collection.add(this.data)
      }
    } catch (error) {
      throw new Error(error?.message)
    }
  }

  static async updateEntity(values) {
    await this.collection.doc(this.id).update(values)
  }

  get id() {
    return this.data.id ? this.data.id : false
  }
}

export class UserEntity extends FirestoreEntity {
  constructor(data) {
    super('users', data)
  }

  async createEntity() {
    await super.createEntity({
      ...this.data,
    })
    const initialKYC = {
      birthplace: '',
      dob: '',
      first_name: '',
      last_name: '',
      third_name: '',
      passport_code: '',
      passport_date: '',
      passport_number: '',
      passport_images: [],
    }
    await firestore.collection('kyc').doc(this.data.id).set(initialKYC)
    return true
  }
}
