export function CloseIcon({ className, onClick }) {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <g fill="none" fillRule="evenodd">
        <rect fill="#FAFAFD" width="33" height="33" rx="4" />
        <path d="M5 6h22v22H5z" />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M16 15.667 20.667 11 22 12.333 17.333 17 22 21.667 20.667 23 16 18.333 11.333 23 10 21.667 14.667 17 10 12.333 11.333 11z"
        />
      </g>
    </svg>
  )
}
