import logo from "assets/images/logo-text.svg"
import { Button, TextInput } from "components/form"
import { Container, HeadingRow } from "components/layout"
import { NavLink } from "components/navigation/NavLink"
import { useAuth } from "hooks/useAuth"
import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import styles from "./Login.module.scss"

const errorsTranslations = {
  "auth/user-not-found": 'Пользователь с таким e-mail не найден.',
  "auth/wrong-password": 'Неправильный пароль.'
}

export const Login = props => {
  const { login } = useAuth()
  const history = useHistory()
  const [errors, setErrors] = useState({})
  const handleSubmit = async ev => {
    ev.preventDefault()
    ev.stopPropagation()
    const { email, password } = ev.currentTarget

    login(email.value, password.value, {
      onFail(error) {
        setErrors(state => ({ ...state, [error.code]: error.message }))
      },
    })
  }
  return (
    <Container>
      <div className={styles.container}>
        <img src={logo} className={styles.logoText} />
        <HeadingRow
          className="no-gap"
          heading={<h2 className={styles.heading}>Добро пожаловать</h2>}
          subheading={
            <p className={styles.subheading}>
              Платформа покупки цифровых <br />
              финансовых активов / ЦФА
            </p>
          }
        />
        <form onSubmit={handleSubmit}>
          <TextInput
            required
            name={"email"}
            placeholder="Введите e-mail"
            error={errors["auth/user-not-found"]}
          />
          {errors["auth/user-not-found"] && (
            <div style={{ marginTop: "10px", color: "#c70000" }}>
              {errorsTranslations["auth/user-not-found"]}
            </div>
          )}
          <TextInput
            required
            type="password"
            name={"password"}
            placeholder="Введите пароль"
            error={errors["auth/user-not-found"]}
          />
          {errors["auth/wrong-password"] && (
            <div style={{ marginTop: "10px", color: "#c70000" }}>
              {errorsTranslations["auth/wrong-password"]}
            </div>
          )}
          <Button>Войти</Button>
        </form>
        <NavLink
          className={styles.resetPass}
          to="/restore-password">
          ВОССТАНОВИТЬ ПАРОЛЬ
        </NavLink>
        <NavLink
          type="button"
          to={"/register"}
          color="buttonBlue"
          style={{ marginTop: "auto" }}>
          Зарегистрироваться
        </NavLink>
        <small className={styles.version}>Версия 1.0.0</small>
      </div>
    </Container>
  )
}
